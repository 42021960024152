export const defaultFeaturesDict = {
  kern: true,
  liga: true,
  salt: true,
  calt: true,
};

export const defaultFeatureCodes = Object.keys(defaultFeaturesDict);

export const ignoredFeatureCodes = [
  "aalt",
  "dnom",
  "numr",
  "ordn",
  "sinf",
  "subs",
  "sups",
  "locl",
  "pnum",
  "ccmp",

  // Defaults
  "kern",
  "liga",
  "salt",
  "calt",
];

export const exclusiveFeatureCodes = ["pnum", "lnum", "onum", "tnum"];

export const multiFeatureCodes = [
  "case",
  "frac",
  "dlig",
  "ss01",
  "ss02",
  "ss03",
  "ss04",
  "ss05",
  "ss06",
  "ss07",
  "ss08",
  "ss09",
  "ss10",
  "ss11",
  "ss12",
  "ss13",
  "ss14",
  "ss15",
  "ss16",
  "ss17",
  "ss18",
  "ss19",
  "ss20",
];

export const allFeatureCodes = [...exclusiveFeatureCodes, ...multiFeatureCodes];
