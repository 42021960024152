import { Controller } from "@hotwired/stimulus";

/**
 * Handles the "Licensed Website" section on the Cart page.
 *
 * - shows website URL input, if an option is selected other than "No website"
 */
export default class extends Controller {
  static targets = ["urlSection"];
  static websiteTypeSelector =
    'input[type="radio"][name="order[licensed_website_type]"]';
  static websiteUrlInputSelector = 'input[name="order[licensed_website_url]"]';
  static testWebsiteUrlInputSelector =
    'input[name="order[licensed_test_website_url]"]';

  connect() {
    this.element
      .querySelectorAll(this.constructor.websiteTypeSelector)
      .forEach((radio) => {
        radio.addEventListener("change", this.updateUrlFields);
      });

    setTimeout(() => {
      this.updateUrlFields();
    }, 1);
  }

  disconnect() {
    this.element
      .querySelectorAll(this.constructor.websiteTypeSelector)
      .forEach((radio) => {
        radio.removeEventListener("change", this.updateUrlFields);
      });
  }

  updateUrlFields = () => {
    const value = this.element.querySelector(
      `${this.constructor.websiteTypeSelector}:checked`
    ).value;

    console.log("Licensed website type:", value);

    const websiteUrlInput = document.querySelector(
      this.constructor.websiteUrlInputSelector
    );
    websiteUrlInput.disabled = value !== "final";
    websiteUrlInput.required = value === "final";

    const testWebsiteUrlInput = document.querySelector(
      this.constructor.testWebsiteUrlInputSelector
    );
    testWebsiteUrlInput.disabled = value !== "test";
    testWebsiteUrlInput.required = value === "test";
  };
}
