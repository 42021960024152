// Background graphics for the menu

import { shapeLetters } from "./shapes/data";

// Select a random shape for each position
Object.keys(shapeLetters).forEach((shapeGroup) => {
  const fontFilePath = shapeFontPaths[shapeGroup]; // `shapeFontPaths` defined in app/views/layouts/store.html.erb

  const customFont = new FontFace(
    `ft-menu-${shapeGroup}`,
    `url(${fontFilePath}`
  );

  customFont.load().then(function (_loadedFont) {
    const shapeLetter = shapeLetters[shapeGroup];
    const randomShapeLetter = Math.floor(Math.random() * shapeLetter.length);
    const shapeDisplay = document.getElementById(`factDisplay${shapeGroup}`);
    shapeDisplay.innerHTML = shapeLetter[randomShapeLetter];

    // Fade in smoothly, but if the page has has been loaded quickly then also fade in quickly
    const seconds = Math.floor(performance.now() / 1000);
    shapeDisplay.style.transition = `opacity ${Math.min(0.5, seconds / 2)}s`;
    shapeDisplay.style.opacity = 1;
  });
});

function setRandomColor(selector, colors) {
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  const element = document.querySelector(selector);
  if (element) {
    element.style.color = randomColor;
  }
}

setRandomColor("#factDisplay1", [
  "rgb(255, 255, 0)",
  "rgb(255, 0, 126)",
  "rgb(0, 0, 255)",
  "rgb(0, 254, 129)",
]);

setRandomColor("#factDisplay2", [
  "rgb(255, 192, 0)",
  "rgb(255, 0, 190)",
  "rgb(0, 63, 255)",
  "rgb(3, 255, 64)",
]);

setRandomColor("#factDisplay3", [
  "rgb(254, 128, 2)",
  "rgb(255, 0, 255)",
  "rgb(2, 127, 255)",
  "rgb(3, 255, 0)",
]);

setRandomColor("#factDisplay4", [
  "rgb(129, 255, 0)",
  "rgb(254, 1, 0)",
  "rgb(127, 0, 255)",
  "rgb(3, 255, 255)",
]);

//------------------------------------------------------------

function randomMargin() {
  document.querySelectorAll(".random--margin").forEach(function (el) {
    randomizeObject(el);
  });
}
function randomizeObject(el) {
  var randomnumber1 = Math.floor(Math.random() * 50);
  var randomnumber2 = Math.floor(Math.random() * 15);
  var randomnumber3 = Math.floor(Math.random() * 4);

  el.style.paddingRight = randomnumber1 + "vw";
  el.style.paddingLeft = randomnumber2 + "vw";
  el.style.marginTop = randomnumber3 + "1px";
}
randomMargin();

//------------------------------------------------------------

document.addEventListener("DOMContentLoaded", () => {
  const checkbox = document.getElementById("hamburger");
  let isOpen = checkbox.checked;

  const $openIcon = $(".open");
  const $closeIcon = $(".close");

  if (isOpen) {
    document.body.classList.add("menu-open");
    $openIcon.hide();
    $closeIcon.show();
  }

  checkbox.addEventListener("change", (event) => {
    isOpen = event.target.checked;

    if (isOpen) {
      $openIcon.hide(200);
      $closeIcon.show(200);
      // Tell the layout the menu is open so that it can disable the scrollbar.
      document.body.classList.add("menu-open");
    } else {
      $openIcon.show(200);
      $closeIcon.hide(200);
      document.body.classList.remove("menu-open");
    }
  });
});
