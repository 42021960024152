export function isBlank(value) {
  return value === null || typeof value === "undefined";
}
export function isPresent(value) {
  return !isBlank(value);
}

/**
 * Call a function with the value if the value is present.
 * @param {T | null | undefined} value
 * @param {(value: T) => R} callback
 * @returns {R | null}
 */
export function withValue(value, callback) {
  if (isPresent(value)) {
    return callback(value);
  } else {
    return null;
  }
}
