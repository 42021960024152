import "common/components/custom-range-slider";
import { h } from "common/dom-builder";
import { smallIconButton } from "common/icon-buttons";
import { preferences } from "../preferences";
import { isPresent } from "common/utils/blank";
import { shortenSampleTextToLimitHeight } from "../config";

const minFontSize = 12;
const maxFontSize = 600;
const maxScaleWhenMaxHeightSet = 2;

export class FontSizeSelector {
  parent;
  element;

  constructor(props) {
    this.element = props.element ?? props.panel?.panelElement;
    this.props = props;
    this.init();
  }

  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    if (preferences.showFontSizePanelInHeader) {
      const element = h(
        "div",
        {
          class: ["input-group", "size-input-group", "in-header"],
          "data-input-group": "size",
        },
        []
      );
      new FontSizeSelector({ typeTester, element });
      return element;
    } else {
      return h(
        "div",
        {
          class: [
            "input-group",
            "size-input-group",
            "expandable",
            // "expand-with-pushdown", // feature panel is the only one pushing down
          ],
          "data-input-group": "size",
        },
        [
          h("span", { class: "label" }, "Size"),
          h("span", { class: "icon-button expand-button" }),
        ]
      );
    }
  }

  get initialFontSize() {
    return this.props.typeTester.props.format.fontSize;
  }

  get hasMaxHeight() {
    return isPresent(this.props.typeTester.props.options.maxHeight);
  }

  get calculatedMaxFontSize() {
    if (shortenSampleTextToLimitHeight() && this.hasMaxHeight) {
      // Limit the font size so that the box cannot get too tall #safarimaxheightissue_option6
      return Math.min(
        maxFontSize,
        Math.round(this.initialFontSize * maxScaleWhenMaxHeightSet)
      );
    }

    return maxFontSize;
  }

  init() {
    this.render();
    this.updateControlWhenFontSizeChanges();
  }

  render() {
    this.element.appendChild(
      h(
        "div",
        {
          class: ["with-icon-in-front", "with-value-indicator", "tool-panel"],
        },
        [
          smallIconButton("size"),
          this.buildInput(),
          this.renderValueIndicator(),
        ]
      )
    );
    this.change(this.initialFontSize);
  }

  updateControlWhenFontSizeChanges() {
    this.props.typeTester.addEventListener("change", (ev) => {
      if (ev.detail.format.fontSize === undefined) return;
      if (ev.detail.format.fontSize === this.value) return;
      this.handleChange(ev.detail.format.fontSize);
    });
  }

  // Value changed by this control
  change(size) {
    this.value = size;
    this.props.typeTester.textEditor.setFontSize(size);
    this.valueIndicatorElement.textContent = size;
  }

  // Value changed by another control
  handleChange(size) {
    this.value = size;
    this.inputElement.value = size;
    this.valueIndicatorElement.textContent = size;
  }

  buildInput() {
    const inputElement = (this.inputElement = h("custom-range-slider", {
      min: minFontSize,
      max: this.calculatedMaxFontSize,
      step: 1,
      value: this.initialFontSize,
    }));
    inputElement.addEventListener("input", (ev) => {
      this.change(ev.target.value);
    });
    inputElement.addEventListener("change", (ev) => {
      this.change(ev.target.value);
      this.props.typeTester.element.dispatchEvent(
        new CustomEvent("interaction")
      );
    });
    this.props.typeTester.addEventListener("reset", () => {
      inputElement.value = this.initialFontSize;
    });
    return inputElement;
  }

  renderValueIndicator() {
    return (this.valueIndicatorElement = h(
      "span",
      { class: ["value-indicator", "tabular-numbers"] },
      [`${this.initialFontSize}`]
    ));
  }
}
