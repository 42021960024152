import { TypeTester } from "./TypeTester";

// Entry point for the type tester

export async function initEditor(editorContainer) {
  return TypeTester.byElement(editorContainer);
}

document.addEventListener("DOMContentLoaded", () => {
  const editors = document.querySelectorAll(".type-tester");

  const lowPri = new Set(Array.from(editors).map((editor) => editor.id));
  const highPri = new Set();

  // Prioritize initializing editors that are in view
  let observer;
  if ("IntersectionObserver" in window) {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (lowPri.has(entry.target.id)) {
              highPri.add(entry.target.id);
              lowPri.delete(entry.target.id);
            }
          } else {
            if (highPri.has(entry.target.id)) {
              lowPri.add(entry.target.id);
              highPri.delete(entry.target.id);
            }
          }
        });
      },
      {
        threshold: 0,
        rootMargin: "50%",
      }
    );
    editors.forEach((editor) => {
      observer.observe(editor);
    });
  }

  // Initialize editors from highPri and lowPri
  const interval = 10;
  const initNext = () => {
    let editor;
    if (highPri.size > 0) {
      const id = highPri.values().next().value;
      highPri.delete(id);
      editor = document.getElementById(id);
    } else if (lowPri.size > 0) {
      const id = lowPri.values().next().value;
      lowPri.delete(id);
      editor = document.getElementById(id);
    }
    if (editor) {
      if (observer) {
        observer.unobserve(editor);
      }
      initEditor(editor);
    }
    setTimeout(initNext, interval);
  };
  initNext();
});

document.addEventListener("click", (event) => {
  TypeTester.handleDocumentClick(event);
});
