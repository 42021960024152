// @ts-check
import { TypeTester } from "./TypeTester";
import { applyAscendersDescenderCorrection } from "./text-editor/ascender-padding";

/**
 *
 * @param {TypeTester} typeTester
 */
export function buildFontScalerForTypeTester(typeTester) {
  // @ts-ignore
  const FontScaler = window.FontScaler;

  const fontScaler = new FontScaler(typeTester.renderedTextElement, {
    ...typeTester.props.options.fontScaler,
    ...typeTester.props.options,
    dimensionElement: typeTester.element,
    loggingId: typeTester.loggingId,
    callbacks: {
      applyColumns: (columns) => {
        if (!typeTester.textEditor.editorElement) {
          throw new Error(
            "No typeTester.textEditor.editorElement in applyColumns"
          );
        }
        typeTester.textEditor.applyColumns(columns);
      },
      applyMaxHeight: (maxHeight) => {
        if (!typeTester.textEditor.editorElement) {
          throw new Error(
            "No typeTester.textEditor.editorElement in applyMaxHeight"
          );
        }
        // The max-height should be on the same element as the columns, otherwise Safari behaves weirdly with overflowing texts
        typeTester.textEditor.editorElement.style.maxHeight =
          maxHeight !== null ? `${maxHeight}px` : "";
      },
      applyAscendersDescenderCorrection: (
        ascenderCorrection,
        descenderCorrection
      ) => {
        if (!typeTester.textEditor.editorElement) {
          throw new Error(
            "No typeTester.textEditor.editorElement in applyMaxHeight"
          );
        }
        applyAscendersDescenderCorrection(
          typeTester.textEditor.editorElement,
          ascenderCorrection,
          descenderCorrection
        );
      },
    },
  });

  typeTester.renderedTextElement.addEventListener("change", (event) => {
    fontScaler.automaticColumnsEnabled =
      event.detail.format.automaticColumnsEnabled;
  });

  return fontScaler;
}
