import debug from "debug";

/**
 * Web Component to replace the native range input.
 *
 * The native range input does not work well in Firefox and the Quill editor.
 * It seems to get stuck when you format a subselection of text when Quill changes the HTML structure.
 */
class CustomRangeSlider extends HTMLElement {
  constructor() {
    super();
    this.value = 0; // Default value
    this.min = 0; // Default min value
    this.max = 100; // Default max value
    this.step = 1; // Default step value
    this.thumb = null;
    this.track = null;
    this.size = 13;
    this.log = debug("custom-range-slider");
  }

  connectedCallback() {
    this.attachShadow({ mode: "open" });
    this.render();
  }

  render() {
    const size = this.size;

    // .track
    //   Black line in the middle
    //
    // .thumb
    //   The circle that you drag
    //
    // .thumb-range
    //    This is needed so we can set the thumb's left to a percentage and also account for the size of the thumb.
    //    We need to use percentage because at initialization we don't know the width of the container.
    //
    const template = `
      <style>
       .container {
          width: 100%;
          display: grid;
          place-items: center;
          position: relative;
          height: ${size}px;
        }
       .track {
          position: absolute;
          height: 1px;
          width: 100%;
          background: black;
        }

        .thumb-range {
          position: absolute;
          inset: 0;
          margin-right: ${size}px;
          height: ${size}px;
        }
       .thumb {
          position: absolute;
          left: 50%;
          width: ${size}px;
          height: ${size}px;
          background: black;
          border-radius: 50%;
          cursor: pointer;
        }
      </style>
      <div class="container">
        <div class="track"></div>
        <div class="thumb-range">
          <div class="thumb"></div>
        </div>
      </div>
    `;
    this.shadowRoot.innerHTML = template;
    this._container = this.shadowRoot.querySelector(".container");
    this.thumb = this.shadowRoot.querySelector(".thumb");
    this.track = this.shadowRoot.querySelector(".track");

    this.updateThumbPosition();

    this.thumb.addEventListener(
      "pointerdown",
      this.handleThumbPointerDown.bind(this)
    );

    this._container.addEventListener(
      "pointerdown",
      this.handleContainerPointerDown.bind(this)
    );
  }

  updateThumbPosition() {
    if (!this.thumb) return;

    this.thumb.style.left = `${
      ((this.value - this.min) / (this.max - this.min)) * 100
    }%`;
  }

  get _availableWidth() {
    return this._container.clientWidth - this.size;
  }

  handleThumbPointerDown(event) {
    this.log(`handleThumbPointerDown ${event.pointerType} ${event.button}`);
    if (event.pointerType === "mouse" && event.button !== 0) return; // handle main button only

    // Ensure that the click and drag does not cause the editor to change the selection
    event.preventDefault();
    event.stopPropagation();

    const initialPercentage = (this.value - this.min) / (this.max - this.min);

    this.handleDragging(event.clientX, initialPercentage);
  }

  handleContainerPointerDown(event) {
    this.log(`handleContainerPointerDown ${event.pointerType} ${event.button}`);
    if (event.pointerType === "mouse" && event.button !== 0) return; // handle main button only

    event.preventDefault();
    event.stopPropagation();

    const containerX = this._container.getBoundingClientRect().x;
    const mouseDx = event.clientX - containerX;

    const containerWidth = this._container.clientWidth;
    const availableWidth = this._availableWidth;
    const offset = (containerWidth - availableWidth) / 2; // half of the thumb width

    const percentage = (mouseDx - offset) / availableWidth;

    this.updateValueFromPercentage(percentage);

    this.handleDragging(event.clientX, percentage);
  }

  // Called after the initial mousedown event is handled.
  // Handles the dragging motion and release.
  handleDragging(initialClientX, initialPercentage) {
    const onPointerMove = (event) => {
      this.log(`handleDragging/onPointerMove ${event.clientX}`);
      const dx = event.clientX - initialClientX;
      const percentage = initialPercentage + dx / this._availableWidth;

      this.updateValueFromPercentage(percentage);
    };

    const onPointerUp = (event) => {
      this.log(`handleDragging/onPointerUp ${event.clientX}`);
      document.removeEventListener("pointermove", onPointerMove);
      document.removeEventListener("pointerup", onPointerUp);

      this.dispatchEvent(new CustomEvent("change", { detail: this.value }));
    };

    document.addEventListener("pointermove", onPointerMove);
    document.addEventListener("pointerup", onPointerUp);
  }

  updateValueFromPercentage(percentage) {
    this._value = this.valueFromPercentage(percentage);

    this.updateThumbPosition();

    this.dispatchEvent(new CustomEvent("input", { detail: this.value }));
  }

  constrainValue(v) {
    v = Math.max(this.min, Math.min(this.max, v));
    v = Math.round(v / this.step) * this.step;
    return v;
  }

  valueFromPercentage(p) {
    return this.constrainValue(this.min + (this.max - this.min) * p);
  }

  static get observedAttributes() {
    return ["value", "min", "max", "step"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case "value":
        this.value = this.constrainValue(parseFloat(newValue));
        break;
      case "min":
        this.min = parseFloat(newValue);
        this.value = this.constrainValue(this.value);
        break;
      case "max":
        this.max = parseFloat(newValue);
        this.value = this.constrainValue(this.value);
        break;
      case "step":
        this.step = parseFloat(newValue);
        this.value = this.constrainValue(this.value);
        break;
    }
    this.updateThumbPosition();
  }

  get value() {
    return this._value;
  }

  set value(v) {
    this._value = this.constrainValue(v);
    this.updateThumbPosition();
  }
}

customElements.define("custom-range-slider", CustomRangeSlider);
