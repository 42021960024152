import { isLhUnitSupported } from "common/utils/browser-detection";

export let preferences = {
  // Show formatting tools when hovered (otherwise, always shown)
  // false requested
  showToolsByDefault: false,

  // Expand panels when hovered (otherwise, only when clicked)
  // true requested; false helps during development
  showPanelsOnHover: true,

  // Show the OT panel (increase the height of the sample) when hovered, otherwise only when clicked
  expandSampleOnHover: false,

  // Show the font style even when the sample is not hovered/active
  // false requested
  alwaysShowFontStyle: false,

  // Include the font family name, in addition to the style name (e.g. "Baton Condensed Medium" as opposed to "Condensed Medium")
  // false requested
  showFontFamilyName: false,

  // true requested
  spacingPanelCoversLabel: true,

  // true requested
  showFontSizePanelInHeader: true,

  // size, feature, spacing, alignment, columns, reset
  // toolOrder: "size, feature, spacing, alignment, columns, reset",
  toolOrder: "size, feature, spacing, alignment, columns, reset",

  // true requested
  expandFeaturePanelOnFocus: true,

  showSpacingPanelInHeader: true,

  // if expandSampleOnHover is enabled, this option will deactivate the sample when the mouse leaves the sample area even if the sample was clicked on
  deactivateOnMouseLeave: false,

  // #safarimaxheightissue_option6
  shortenSampleTextToLimitHeight: true,

  // #safarimaxheightissue_option8
  useLhUnitsToLimitHeight: true,
};

// const storedValue = localStorage.getItem("typeTesterPreferences");
// if (storedValue) {
//   try {
//     const parsed = JSON.parse(storedValue);
//     Object.entries(preferences).forEach(([key, value]) => {
//       preferences[key] = parsed[key] ?? value;
//     });
//   } catch (e) {
//     console.error("Error parsing stored preferences:", e);
//   }
// }

// const style = "color: #630; font-size: 125%; background: #FED;";

// window.updateTypeTesterPreferences = (newPreferences) => {
//   preferences = { ...preferences, ...newPreferences };
//   localStorage.setItem("typeTesterPreferences", JSON.stringify(preferences));
//   console.log("%cUpdated preferences:", style, preferences);
//   console.log("%cReloading...", style);
//   window.location.reload();
// };

// window.resetTypeTesterPreferences = () => {
//   localStorage.setItem("typeTesterPreferences", "");
//   console.log("%Preferences have been reset:", style, preferences);
//   console.log("%cReloading...", style);
//   window.location.reload();
// };

// console.log(
//   `%cupdateTypeTesterPreferences(${JSON.stringify(preferences, null, 2)})`,
//   style
// );
