import $ from "jquery";

$(".font_families.show").ready(function () {
  $('.font_style_sample input[type="range"]').on("input change", function () {
    var value = $(this).val();
    $(this)
      .parent()
      .siblings(".rendered_text")
      .css("font-size", value + "px");
  });

  const staticButton = document.querySelector(".submenu .buy-button");
  const fixedContainer = document.querySelector(
    ".floating-buy-button-container"
  );

  if (staticButton) {
    // Remember the initial position of the nav, when it's not fixed yet
    const offsetTop = staticButton.getBoundingClientRect().top + window.scrollY;

    // State
    let isFixed = false;

    // Copy button
    const fixedButton = staticButton.cloneNode(true);
    fixedContainer.appendChild(fixedButton);

    const updateWhetherFixed = () => {
      const fixedTop = fixedContainer.getBoundingClientRect().top;

      if (staticButton.getBoundingClientRect().top <= fixedTop) {
        if (!isFixed) {
          isFixed = true;
          staticButton.classList.add("hidden");
          fixedContainer.classList.remove("hidden");
        }
      } else {
        if (isFixed) {
          isFixed = false;
          staticButton.classList.remove("hidden");
          fixedContainer.classList.add("hidden");
        }
      }
    };

    window.addEventListener("scroll", updateWhetherFixed);
    window.addEventListener("resize", updateWhetherFixed);

    updateWhetherFixed();
  }

  $(window).scroll(function () {
    if ($(".licensing").length > 0) {
      let top = $(".licensing")[0].getBoundingClientRect().top;
      let $buyButton = $(".buy-button--top");
      if (top < 90) {
        $buyButton.css("opacity", 0);
      } else {
        $buyButton.css("opacity", 100);
      }
    }
  });
});
