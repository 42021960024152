import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    message: String,
  };

  connect() {
    this.tooltip = document.createElement("div");
    this.tooltip.classList.add("tooltip");
    document.body.appendChild(this.tooltip);
  }

  show(event) {
    if (!this.messageValue) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.tooltip.textContent = this.messageValue;
    this.positionTooltip(event);
    this.tooltip.style.opacity = 0;
    this.tooltip.style.display = "block";
    this.timeout = setTimeout(() => {
      this.tooltip.style.opacity = 1;
    }, 1);
  }

  hide() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.tooltip.style.opacity = 0;

    this.timeout = setTimeout(() => {
      this.tooltip.style.display = "none";
    }, 500);
  }

  positionTooltip(event) {
    const { clientX: x, clientY: y } = event;
    const tooltipRect = this.tooltip.getBoundingClientRect();
    const offset = 10; // Space between cursor and tooltip
    this.tooltip.style.left = `${x + offset}px`;
    this.tooltip.style.top = `${y + offset}px`;

    // Adjust position if tooltip overflows the viewport
    if (x + tooltipRect.width + offset > window.innerWidth) {
      this.tooltip.style.left = `${x - tooltipRect.width - offset}px`;
    }
    if (y + tooltipRect.height + offset > window.innerHeight) {
      this.tooltip.style.top = `${y - tooltipRect.height - offset}px`;
    }
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.remove();
    }
  }
}
