//@ts-check

import {
  allFeatureCodes,
  defaultFeatureCodes,
  ignoredFeatureCodes,
} from "./config";

/**
 * Filter out unknown features provided by the font.
 * @param {{ tag: string, name: string }[]} features Array of feature objects, example: [{ tag: "kern", name: "Kerning" }]
 * @returns
 */
export function filterFeatures(features) {
  /** @type {{ tag: string, name: string }[]} r */
  const r = [];
  const unprocessedFeatures = [...features];

  allFeatureCodes.forEach((feature) => {
    const index = unprocessedFeatures.findIndex((f) => f.tag === feature);
    if (index !== -1) {
      // If the feature is known, include it in the result
      if (!ignoredFeatureCodes.includes(feature)) {
        r.push(unprocessedFeatures[index]);
      }
      // Remove from unprocessed features
      unprocessedFeatures.splice(index, 1);
    }
  });

  unprocessedFeatures.forEach((feature) => {
    if (!ignoredFeatureCodes.includes(feature.tag)) {
      console.warn(`Unexpected feature:`, feature.tag, feature.name);
      r.push(feature);
    }
  });
  return r;
}

/**
 * Remove default features from the list of features.
 * @param {string[]} featureCodes
 */
export function withoutDefaultFeatures(featureCodes) {
  return featureCodes.filter((code) => !defaultFeatureCodes.includes(code));
}
