//@ts-check
import debug from "debug";
import $ from "jquery";
import {
  shortenSampleTextToLimitHeight,
  useLhUnitsToLimitHeight,
} from "./type-tester/config";
import { correctionPixels } from "./type-tester/text-editor/ascender-padding";

let lastId = 0;

/**
 * Scales font samples according to screen width.
 *
 * Usage:
 *
 *   FontScaler.startAll(".font_style_sample", { scaleUp: true });
 *
 * Side effects:
 * - data attribute 'fontSize' - this will be used as the basis, which means the font size meant for the 1000px width; if missing, it will be initialized from the actual size on the first call
 *
 */
class FontScaler {
  /**
   * @typedef {import("./type-tester/types").TypeTesterFontSettings} FontSettings
   */
  /**
   * @typedef {Object} Config
   * @prop {number} [upSpeed] The speed of scaling up
   * @prop {number} [downSpeed] The speed of scaling down
   * @prop {number} [maxScale] The maximum scaling factor
   * @prop {number} [minScale] The minimum scaling factor
   * @prop {number} [maxFontSize] The maximum font size
   * @prop {number} [minFontSize] The minimum font size
   * @prop {number} [paddingX] The padding to subtract from the width when calculating the ratio
   * @prop {boolean} [scaleUp] Whether to scale up
   * @prop {function} [onBeforeResize] Function to call before resizing an element; called with a JQuery element
   * @prop {function} [onAfterResize] Function to call after resizing an element; called with a JQuery element
   * @prop {number} [maxHeight] The maximum height of the element
   * @prop {number} [columnWidth] The max width of a column in em
   * @prop {boolean} [automaticColumnsEnabled] Whether to automatically calculate the number of columns (default: true if columnWidth is set); this can be used to disable the automatic calculation, e.g. if the number of columns has been selected by the user
   * @prop {HTMLElement} [dimensionElement] The element to use for the width calculation; defaults to window width - paddingX
   * @prop {string} [loggingId] The id to use for logging
   * @prop {Callbacks} [callbacks] Callbacks
   */

  /**
   * @typedef {Object} Callbacks
   * @prop {function} [applyColumns] Function to call to apply the number of columns
   * @prop {function} [applyMaxHeight] Function to call to apply the max height
   * @prop {function} [applyAscendersDescenderCorrection] Function to call to apply the ascender and descender padding
   * @prop {function} [onResize] Function to call when new dimensions are applied
   */

  /**
   * @typedef {Object} Inputs
   * @prop {number} fontSize The unscaled font size
   * @prop {number} lineHeight The line height (e.g. 1, 1.2)
   * @prop {number} availableWidth The width available to display the sample
   * @prop {number | null} columns
   * @prop {FontSettings} fontSettings
   */

  /**
   * @typedef {Object} Results
   * @prop {number} scaledFontSize The scaled font size
   * @prop {number} scalingFactor The scaling factor
   * @prop {number | null} height The height of the element or null for auth
   * @prop {number | null} lines The number of lines the height has been calculated for
   * @prop {number | null} columns The number of columns the text should be split into (null if the columns are not set)
   * @prop {boolean} wrap Whether the text should wrap
   * @prop {number} ascenderCorrection How many pixels to add to the top in addition to the line-height so that the ascenders are fully visible
   * @prop {number} descenderCorrection How many pixels to add to the bottom in addition to the line-height so that the descenders are fully visible
   */

  /** @type {Config} */
  config;

  isActive = false;

  /** @type {FontSettings?} */
  fontSettings;

  /**
   * @param {HTMLElement} element
   * @param {Config} config
   */
  constructor(element, config = {}) {
    if (!element) {
      throw new Error("font-scaler: no element provided");
    }

    const debugId = ++lastId;
    this.element = element;
    this.config = config;

    if (element.dataset.scaleDownSpeed) {
      this.config.downSpeed = parseFloat(element.dataset.scaleDownSpeed);
    }

    this.log = debug(`font-scaler:${config.loggingId ?? debugId}:FontScaler`);
    this.log("initialized", config);
  }

  /**
   * @param {HTMLElement} element
   * @param {Config} config
   */
  static start(element, config) {
    const fontScaler = new FontScaler(element, config);
    fontScaler.start();
    return fontScaler;
  }

  /**
   * Start scaling all elements with the class .rendered_text within the provided selector.
   *
   * @param {string} selector CSS selector used as a scope, we'll look for .rendered_text within this.
   * @param {Config} options optional
   */
  static startAll(selector, options) {
    $(`${selector ?? ""} .rendered_text`).each((index, element) => {
      const fontScaler = new FontScaler(element, options);
      fontScaler.start();
    });
  }

  start() {
    this.saveUnscaledFontSizeIfMissing();

    this.lineHeight = null;

    if (!this.element.dataset.unscaledFontSize) {
      throw new Error("element.dataset.unscaledFontSize is missing");
    }

    this.unscaledFontSize = this.element.dataset.unscaledFontSize;

    this.listenToWindowResize();
    this.listenToTypeTesterChanges();

    this.isActive = true;

    this.log("initial resize");
    this.resizeIfActive();
  }

  saveUnscaledFontSizeIfMissing() {
    // Remember the font size on the first call
    if (!this.element.dataset.unscaledFontSize) {
      this.element.dataset.unscaledFontSize = parseFloat(
        this.element.style.fontSize
      ).toString();
    }
  }

  listenToWindowResize() {
    window.addEventListener("resize", () => {
      this.log("window resized");
      this.resizeIfActive();
    });
  }

  listenToTypeTesterChanges() {
    // Listen to Type-Tester changes, so we have the line-height for the max-height calculation
    this.element.addEventListener("change", (event) => {
      /** @type {import('./type-tester/types').TypeTesterChangeEventDetail} */
      // @ts-ignore
      const detail = event.detail;

      let changed = false;
      const newLineHeight = detail.format.lineHeight
        ? parseFloat(detail.format.lineHeight) / 100
        : null;

      if (this.lineHeight != newLineHeight) {
        this.lineHeight = newLineHeight;
        this.log("line height changed", this.lineHeight);
        changed = true;
      }

      const newFontSize = parseFloat(detail.format.fontSize);
      if (this.unscaledFontSize != newFontSize) {
        this.unscaledFontSize = newFontSize;
        this.log("font size changed", this.unscaledFontSize);
        changed = true;
      }

      const newColumns = detail.format.columns;
      if (this.columns !== newColumns) {
        this.columns = newColumns;
        this.log("columns changed", this.columns);
        changed = true;
      }

      if (this.fontSettings !== detail.format.fontSettings) {
        this.fontSettings = detail.format.fontSettings;
        this.log("font settings changed", this.fontSettings);
        changed = true;
      }

      if (changed) {
        this.resizeIfActive();
      }
    });
  }

  resizeIfActive() {
    if (!this.isActive) return;

    this.beforeResizeCallback();

    const availableWidth =
      this.config.dimensionElement?.clientWidth ??
      window.innerWidth - (this.config.paddingX ?? 30);

    /** @type {Inputs} */
    const inputs = {
      fontSize: parseFloat(this.unscaledFontSize),
      lineHeight: this.lineHeight ?? 1,
      availableWidth,
      columns: this.columns,
      fontSettings: this.fontSettings,
    };

    /** @type {Results} */
    const results = calculate(this.config, inputs, this.log);

    this.applyResults(results);

    // Save scaling factor so that other components (e.g. type-tester) may use it
    this.element.dataset.scalingFactor = results.scalingFactor.toString();

    this.log("resize", this.config, inputs, results);

    if (this.config.callbacks?.onResize) {
      this.config.callbacks.onResize(this);
    }

    this.afterResizeCallback();
  }

  beforeResizeCallback() {
    if (typeof this.config.onBeforeResize === "function") {
      this.config.onBeforeResize($(this.element));
    }
  }

  afterResizeCallback() {
    if (typeof this.config.onAfterResize === "function") {
      this.config.onAfterResize($(this.element));
    }
  }

  /**
   * @param {Results} results
   */
  applyResults(results) {
    // Apply scaling
    this.element.style.fontSize = `${results.scaledFontSize}px`;

    // Apply max-height
    if (results.lines && results.lines === 1 && results.wrap === false) {
      // If it's a single line, let the horizontal overflow be cut off
      this.element.style.maxHeight = "";
    } else {
      if (useLhUnitsToLimitHeight() && results.lines) {
        // Use lh units to limit the height - safarimaxheightissue_option8
        this.element.style.maxHeight = `${results.lines}lh`;
      } else if (shortenSampleTextToLimitHeight()) {
        // On Safari, we don't set the maxHeight, instead we rely on TextLengthLimiterPlugin to limit the height of the sample.
        // #safarimaxheightissue_option6
      } else {
        if (this.config.callbacks?.applyMaxHeight) {
          this.config.callbacks.applyMaxHeight(results.height);
        } else {
          this.element.style.maxHeight =
            results.height !== null ? `${results.height}px` : "";
        }
      }
    }

    // If it's a single line, let the horizontal overflow be cut off
    this.element.style.whiteSpace = results.wrap ? "" : "nowrap";
    if (results.wrap) {
      this.element.classList.remove("nowrap");
    } else {
      this.element.classList.add("nowrap");
    }

    const overflowStylesCss =
      results.height !== null || results.lines === 1
        ? "overflow: hidden; overflow: clip;"
        : "";
    replaceInStyle(this.element, /overflow\s*:[^;]+;?/gi, overflowStylesCss);

    // Applying column-count 1 makes the browser hide the overflowing line,
    // this way we can allow space for the descenders without including the next line.
    const columns = results.columns ?? 1;

    if (this.config.callbacks?.applyColumns) {
      this.log("calling callbacks.applyColumns", columns);
      this.config.callbacks.applyColumns(columns);
    } else {
      this.element.style.columns = columns.toString();
    }

    if (this.config.callbacks?.applyAscendersDescenderCorrection) {
      this.config.callbacks.applyAscendersDescenderCorrection(
        results.ascenderCorrection,
        results.descenderCorrection
      );
    }
  }

  set upSpeed(value) {
    if (value !== this.config.upSpeed) {
      this.config.upSpeed = value;
      this.log("upSpeed changed", value);
      this.resizeIfActive();
    }
  }

  set downSpeed(value) {
    if (value !== this.config.downSpeed) {
      this.config.downSpeed = value;
      this.log("downSpeed changed", value);
      this.resizeIfActive();
    }
  }

  set maxScale(value) {
    if (value !== this.config.maxScale) {
      this.config.maxScale = value;
      this.log("maxScale changed", value);
      this.resizeIfActive();
    }
  }

  set minScale(value) {
    if (value != this.config.minScale) {
      this.config.minScale = value;
      this.log("minScale changed", value);
      this.resizeIfActive();
    }
  }

  set maxFontSize(value) {
    if (value != this.config.maxFontSize) {
      this.config.maxFontSize = value;
      this.log("maxFontSize changed", value);
      this.resizeIfActive();
    }
  }

  set minFontSize(value) {
    if (value != this.config.minFontSize) {
      this.config.minFontSize = value;
      this.log("minFontSize changed", value);
      this.resizeIfActive();
    }
  }

  set columnWidth(value) {
    if (value != this.config.columnWidth) {
      this.config.columnWidth = value;
      this.log("columnWidth changed", value);
      this.resizeIfActive();
    }
  }

  set maxHeight(value) {
    if (value != this.config.maxHeight) {
      this.config.maxHeight = value;
      this.log("maxHeight changed", value);
      this.resizeIfActive();
    }
  }

  set automaticColumnsEnabled(value) {
    if (value !== this.config.automaticColumnsEnabled) {
      this.config.automaticColumnsEnabled = value;
      this.log("automaticColumnsEnabled changed", value);
      this.resizeIfActive();
    }
  }
}

/**
 * Calculate the scaled font size and height.
 *
 * @param {Config} config
 * @param {Inputs} inputs
 * @returns {Results}
 */
function calculate(config, inputs, log) {
  const {
    scaleUp,
    upSpeed,
    downSpeed,
    maxScale,
    minScale,
    maxFontSize,
    minFontSize,
    maxHeight,
    columnWidth,
  } = config;
  const { fontSize: unscaledFontSize, lineHeight, availableWidth } = inputs;

  ensureNumber(unscaledFontSize, "unscaledFontSize");

  const referenceWidth = 1000;

  const shouldScale = scaleUp || availableWidth < referenceWidth;

  let scalingFactor = 1;

  if (shouldScale) {
    scalingFactor = availableWidth / referenceWidth;

    const diff = scalingFactor - 1;

    if (diff > 0 && typeof upSpeed === "number") {
      scalingFactor = 1 + (diff * (upSpeed ?? 100)) / 100;
    }

    if (diff < 0 && typeof downSpeed === "number") {
      scalingFactor = 1 + (diff * (downSpeed ?? 100)) / 100;
    }

    if (maxScale) {
      scalingFactor = Math.min(maxScale / 100, scalingFactor);
    }

    if (minScale) {
      scalingFactor = Math.max(minScale / 100, scalingFactor);
    }
  }
  ensureNumber(scalingFactor, "scalingFactor");

  // Calculate scaled font-size
  let scaledFontSize = unscaledFontSize * scalingFactor;

  if (maxFontSize) {
    scaledFontSize = Math.min(maxFontSize, scaledFontSize);
  }
  if (minFontSize) {
    scaledFontSize = Math.max(minFontSize, scaledFontSize);
  }
  ensureNumber(scaledFontSize, "scaledFontSize");

  // Calculate height

  let height = null;
  let lines = null;
  let wrap = true;
  let ascenderCorrection = 0;
  let descenderCorrection = 0;

  if (inputs.fontSettings) {
    ascenderCorrection = correctionPixels(
      inputs.fontSettings.minLineHeightAscender,
      lineHeight * 100,
      scaledFontSize
    );
    descenderCorrection = correctionPixels(
      inputs.fontSettings.minLineHeightDescender,
      lineHeight * 100,
      scaledFontSize
    );
  }

  if (typeof maxHeight === "number") {
    const effectiveLineHeight = scaledFontSize * lineHeight;

    lines = Math.floor((maxHeight * scalingFactor) / effectiveLineHeight);
    if (lines < 1) {
      lines = 1;
    }

    wrap = lines > 1;

    const correction = ascenderCorrection + descenderCorrection;

    height = wrap ? Math.ceil(lines * effectiveLineHeight + correction) : null;

    log(
      `- maxHeight = ${maxHeight}\n` +
        `  scalingFactor = ${scalingFactor}\n` +
        `  scaledFontSize = ${scaledFontSize}\n` +
        `  lineHeight = ${lineHeight}\n` +
        `  effectiveLineHeight = ${effectiveLineHeight} // scaledFontSize * lineHeight\n` +
        `  scalingFactor = ${scalingFactor}\n` +
        `  lines = ${lines} // Math.floor((maxHeight * scalingFactor) / effectiveLineHeight)\n` +
        `  wrap = ${wrap} // lines > 1\n` +
        `  height without correction = ${Math.ceil(
          lines * effectiveLineHeight
        )}\n` +
        `  correction = ${correction}\n` +
        `  height = ${height} // Math.ceil(lines * effectiveLineHeight)`
    );
  }

  // Columns

  // log(
  //   "config.automaticColumnsEnabled",
  //   config.automaticColumnsEnabled,
  //   columnWidth
  // );

  let columns = inputs.columns ?? null;
  if (config.automaticColumnsEnabled !== false) {
    if (typeof columnWidth === "number") {
      // Note: using availableWidth/scaledFontSize instead of 1000/unscaledFontSize because scaledFontSize also takes into account min/max settings

      // availableWidth / unscaledFontSize => how many units fit in availableWidth
      // availableWidth / columnWidth / scaledFontSize => how many columns fit in availableWidth
      columns = Math.ceil(availableWidth / columnWidth / scaledFontSize);
      columns = Math.min(3, Math.max(1, columns));
    }
    // log("columns", columns);
  }

  return {
    scalingFactor,
    scaledFontSize,
    height,
    lines,
    columns,
    wrap,
    ascenderCorrection,
    descenderCorrection,
  };
}

function replaceInStyle(element, pattern, replacement) {
  let styleStr = element.getAttribute("style") || "";
  styleStr = styleStr.replace(pattern, "");
  styleStr += replacement;
  element.setAttribute("style", styleStr);
}

function ensureNumber(value, name) {
  if (typeof value !== "number" || isNaN(value)) {
    console.error(`${name} is not a number: ${value} (${typeof value})`);
    throw new Error(`${name} is not a number: ${value} (${typeof value})`);
  }
}

// @ts-ignore
window.FontScaler = FontScaler;
