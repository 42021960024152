//@ts-check
import {
  allFeatureCodes,
  defaultFeatureCodes,
  defaultFeaturesDict,
  exclusiveFeatureCodes,
  ignoredFeatureCodes,
} from "../font-features/config";
import { filterFeatures } from "../font-features/utils";
import { getFontFeatures } from "../font-parsing/font-parsing";
import { h } from "common/dom-builder";

export class FontFeatureSelector {
  id;
  element;
  font;
  props;
  currentFeaturesDict = {};
  showPanelWhileSampleActive = true;

  constructor(props) {
    this.element = props.panel.panelElement;
    this.props = props;
    this.font = props.font;
    this.id = `${this.props.typeTester.id}-feature`; // E.g. 'sample-1-feature'
    this.offsetLeft = this.props.typeTester.element.querySelector(
      ".feature-input-group"
    );
    this.init();
  }

  static buildElementForHeader(typeTester) {
    // Not rendering the label, will be opened automatically, when the sample is focussed
    return null;

    return h(
      "div",
      {
        class: [
          "input-group",
          "feature-input-group",
          "expandable",
          "expand-with-pushdown",
        ],
        "data-input-group": "feature",
      },
      [
        h("span", { class: "label" }, "OpenType"),
        h("span", { class: "icon-button expand-button" }),
      ]
    );
  }

  init() {
    this.props.panel.containerElement.classList.add("distant", "center");

    this.props.typeTester.textEditor.addEventListener(
      "format-change",
      ({ detail }) => {
        // console.log("format-change", detail);
        this.updateCurrentFeatures(detail.features);
      }
    );

    const contentFeatures = this.props.typeTester.props.format.contentFeatures;

    if (contentFeatures) {
      contentFeatures?.split(",").forEach((featureCode) => {
        if (!featureCode) {
          console.warn(
            "featureCode missing - contentFeatures:",
            contentFeatures
          );
          throw new Error("featureCode missing");
        }
        this.currentFeaturesDict[featureCode] = true;
      });
    }

    this.update();
  }

  setFont(font) {
    this.font = font;
    this.update();
  }

  async update() {
    try {
      this.features = filterFeatures(
        await getFontFeatures({
          name: this.font.name,
          src: this.font.otfDesktopFileUrl,
          ext: "otf",
        })
      );
    } catch {
      this.features = [];
    }
    this.render();
  }

  async render() {
    const formElement = h("form", { id: `${this.id}-form` });

    let columnElement;

    const featureCodesAdded = new Set();

    // Find exclusive features
    const exclusiveFeatures = exclusiveFeatureCodes
      .map((featureCode) =>
        this.features.find((feature) => feature.tag === featureCode)
      )
      .filter((feature) => feature);

    const useExclusiveFeatures = exclusiveFeatures.length >= 2;

    for (const feature of this.features) {
      if (featureCodesAdded.size % 5 === 0) {
        columnElement = h("div");
        columnElement.classList.add("column");
        formElement.appendChild(columnElement);
      }

      const type =
        useExclusiveFeatures && exclusiveFeatureCodes.includes(feature.tag)
          ? "radio"
          : "checkbox";
      this.renderItem(columnElement, feature, type);
      featureCodesAdded.add(feature.tag);
    }

    this.element.replaceChildren(formElement);

    this.updateCheckboxStates();
  }

  renderItem(parentElement, feature, type) {
    const itemElement = h("div");
    itemElement.classList.add("item");

    const checked = this.currentFeaturesDict[feature.tag] ?? false;

    if (type === "radio") {
      // Radio buttons - For mutually exclusive features
      addRadioWithLabel(itemElement, {
        id: `${this.id}-${feature.tag}`,
        name: "feature",
        value: feature.tag,
        checked,
        label: feature.name ?? `(${feature.tag})`,
        onChange: (ev) => {
          ev.stopPropagation();
          const newValue = ev.target.checked;

          this.setFeatureValue(feature.tag, newValue, { exclusive: true });
        },
      });
    } else {
      // Checkboxes - For non-exclusive features
      addCheckboxWithLabel(itemElement, {
        id: `${this.id}-${feature.tag}`,
        name: "feature",
        value: feature.tag,
        checked,
        label: feature.name ?? `(${feature.tag})`,
        onChange: (ev) => {
          ev.stopPropagation();
          const newValue = ev.target.checked;
          this.setFeatureValue(feature.tag, newValue, { exclusive: false });
        },
      });
    }

    parentElement.appendChild(itemElement);
  }

  setFeatureValue(featureCode, newValue, { exclusive }) {
    if (!featureCode)
      throw new Error("empty featureCode passed to setFeatureValue");

    if (exclusive) {
      // Turn off all other exclusive features
      exclusiveFeatureCodes.forEach((code) => {
        if (code !== featureCode) {
          delete this.currentFeaturesDict[code];
        }
      });
    }

    this.currentFeaturesDict[featureCode] = newValue;

    this.props.typeTester.textEditor.setFeatures({
      ...defaultFeaturesDict,
      ...this.currentFeaturesDict,
    });

    this.props.typeTester.element.dispatchEvent(new CustomEvent("interaction"));
  }

  // When the user changes the selection, reflect the currently applied features in the UI
  updateCurrentFeatures(featuresDict) {
    featuresDict = withoutKeys(featuresDict, defaultFeatureCodes);
    this.currentFeaturesDict = featuresDict;

    this.update();
  }

  updateCheckboxStates() {
    const featuresDict = this.currentFeaturesDict;

    // If empty
    if (Object.keys(featuresDict).length === 0) {
      // Clear radio button selection
      const formElement = document.getElementById(`${this.id}-form`);
      if (!formElement) {
        console.warn(`Could not find form element ${this.id}-form`);
        return;
      }
      formElement.querySelectorAll("input[type=radio]").forEach((radio) => {
        //@ts-ignore
        radio.checked = false;
      });
      return;
    }

    for (const featureCode in featuresDict) {
      if (defaultFeaturesDict[featureCode]) continue;
      if (!featureCode) {
        console.error("featureCode missing, featuresDict:", featuresDict);
        throw new Error("featureCode missing");
      }
      const radioId = `${this.id}-${featureCode}`;
      const radio = document.getElementById(radioId);
      if (!radio) {
        console.warn(`Could not find radio element ${radioId}`);
        continue;
      }

      //@ts-ignore
      radio.checked = featuresDict[featureCode];
    }
  }
}

function addCheckboxWithLabel(
  parent,
  { id, name, value, label, checked, onChange }
) {
  const input = addCheckbox(parent, { id, name, value, checked });
  input.addEventListener("change", onChange);

  addLabel(parent, { label, inputId: id });
}

function addRadioWithLabel(
  parent,
  { id, name, value, checked, label, onChange }
) {
  const input = addRadio(parent, { id, name, value, checked });
  input.addEventListener("change", onChange);

  addLabel(parent, { label, inputId: id });
}

function addCheckbox(parent, { id, name, value, checked }) {
  const input = h("input", { id, type: "checkbox", name, value });
  input.checked = checked;
  parent.appendChild(input);
  return input;
}

function addRadio(parent, { id, name, value, checked }) {
  const input = h("input", { id, type: "radio", name, value });
  input.checked = checked;
  parent.appendChild(input);
  return input;
}

function addLabel(parent, { label, inputId }) {
  const labelElement = h("label");
  labelElement.htmlFor = inputId;
  labelElement.textContent = label;
  parent.appendChild(labelElement);
  return label;
}

function withoutKeys(obj, keys) {
  const r = { ...obj };
  keys.forEach((key) => delete r[key]);
  return r;
}
