//@ts-check

import Quill from "quill";
import { withoutDefaultFeatures } from "../font-features/utils";

const { StyleAttributor, Scope } = Quill.import("parchment");

// Create a style attributor for font-feature-settings

// Define inline Quill attributors, so that we format any portion of the text like this:
// E.g. `quill.format('font-feature-settings', '"ss01" on')`
["font-family", "font-feature-settings", "letter-spacing"].forEach(
  (property) => {
    Quill.register(
      new StyleAttributor(property, property, {
        whitelist: null,
        scope: Scope.INLINE,
      })
    );
  }
);

// Define block-level Quill attributors.
// Note that font-size must be block-level, since the browser evaluates line-height relative to the font-size. If the line-height is set on the paragraph and font-size in the inner span, then the font-size is not taken into account when calculating the line-height.
["font-size", "line-height"].forEach((property) => {
  Quill.register(
    new StyleAttributor(property, property, {
      whitelist: null,
      scope: Scope.BLOCK,
    })
  );
});

const constrain = (value, min, max) => Math.min(Math.max(value, min), max);

//
// - init(editor) - called when the text editor initialized to get the initial value for the currentState & originalState (which will be used for reset)
// - apply(editor, value) - called during reset to apply the original value

const properties = {
  contentHtml: {
    init: (editor) => editor.element.innerHTML,
    apply: (editor, value) => (editor.element.innerHTML = value),
  },
  fontFamily: {
    // Top-level font-family as it appears in CSS (font-style code), e.g. "f-baton-nouveau-light".
    // Can also be set on a portion of text, in which case the state is stored in the contentHtml.
    init: (editor) => editor.element.style.fontFamily,
    toStyleValue: (value) => value,
  },
  fontStyleId: {
    init: (editor) => {
      const searchCssName = removeQuotes(editor.element.style.fontFamily);
      // this.debug("searchCssName:", JSON.stringify({ searchCssName }));
      const fontStyle = editor.options.fontStyles.find(
        (fs) => fs.cssName === searchCssName
      );
      if (!fontStyle) {
        console.error(
          "Font style not found by cssName",
          editor.element.style.fontFamily,
          "searched in:",
          editor.options.fontStyles.map((fs) => fs.cssName)
        );
        throw new Error("Font style not found by cssName");
      }
      return fontStyle?.id;
    },
  },
  fontSize: {
    toStyleValue: (value) => value,
    normalize: (value) => constrain(Math.round(parseFloat(value)), 12, 600),
  },
  lineHeight: {
    toStyleValue: (value) => value / 100,
    normalize: (value) => constrain(Math.round(parseFloat(value)), 90, 200),
  },
  textAlign: {
    toStyleValue: (value) => value,
  },
  columns: {
    toStyleValue: (value) => value,
    normalize: (value) => constrain(parseInt(value), 1, 3),
  },
  letterSpacing: {
    normalize: (value) => constrain(Math.round(parseFloat(value)), -12, 25),
  },
  // contentFeatures: OT features used in all of the text, separated by comma, e.g. "ss01,ss02".
  // (features can be set on a portion of text, is stored in the contentHtml)
  contentFeatures: {
    // init: (editor) => editor.options.contentFeatures
  },
};

/** @typedef {any} FontScalerInterface */

/**
 * @typedef {Object} Options
 * @prop {FontScalerInterface} fontScaler
 * @prop {boolean} readOnly
 * @prop {Object} format
 * @prop {any} logContext
 * @prop {any} fontStyles
 */

/**
 * A representation of the editor, wrapper around Quill.
 *
 * Events:
 *   - format-change - detail: { features: { ss01: true, ss02: false }, format: { font-feature-settings: '"ss01" on, "ss02" off' }
 *   - focus-change - detail: { hasFocus: true }
 */
export class TextEditor {
  quill;
  element; // .rendered_text (contains .ql-editor which is contenteditable)
  hasFocus = false;
  lastValidSelection;

  originalParentStyles = {};
  styleKeys = ["fontFamily", "fontSize", "lineHeight", "textAlign", "columns"];

  originalState = {};

  currentState = {
    letterSpacing: null,
    columns: null, // We store the colum in addition to styles, so that it is stored as an integer (css converts values to string), so we can avoid conversion and checking for null/undefined every time we need to use it.
    automaticColumnsEnabled: true, // The columns will be automatically determined by font-scaler; if the user clicks on a column setting, that will disable the automatic behaviour.
    contentFeatures: null, // The OpenType features used in all of the text, separated by comma, e.g. "ss01,ss02"
  };

  /** @type Options */
  options;

  isTextEdited = false;

  /**
   *
   * @param {HTMLDivElement} element The element to pass to Quill editor (.rendered_text), the .ql-editor will be its child.
   * @param {Options} options
   */
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.fontScaler = options.fontScaler;

    this.debug = options.logContext.extend("TextEditor");
    this.debug("options:", options);
  }

  init() {
    // fontStyle attributes are generated in font_style.type_tester_params
    this.fontStyleByCssName = this.options.fontStyles.reduce((acc, style) => {
      acc[style.cssName] = style;
      acc[style.deprecatedCssName] = style;
      return acc;
    }, {});

    // Save original values so that we can use these when the reset button is clicked
    for (const key in properties) {
      const strategy = properties[key];
      if (strategy.init) {
        this.currentState[key] = strategy.init(this);
      } else {
        this.currentState[key] = this.options.format[key];
        if (this.styleKeys.includes(key)) {
          this.originalParentStyles = this.element.style[key];
        }
      }
      this.originalState[key] = this.currentState[key];
    }

    this.debug("originalState", this.originalState);

    this.buildQuill();
    this.dispatchChangeEvent({ reason: "init" });
  }

  reset() {
    this.debug("reset");

    this.debug("state before reset", this.currentState);

    //@ts-ignore
    this.currentState = { ...this.originalState };

    this.editorElement.innerHTML = this.originalState.contentHtml;
    this.isTextEdited = false;

    for (const key in properties) {
      const strategy = properties[key];
      if (strategy.apply) {
        strategy.apply(this, this.originalState[key]);
      } else if (strategy.toStyleValue) {
        this.element.style[key] = strategy.toStyleValue(
          this.originalState[key]
        );
      }
    }

    this.debug("state after reset", this.currentState);

    this.buildQuill();

    // Trigger the selection-change event to initialize variables and emit format-change event so that the controls update
    this.quill.setSelection(0, 0);

    this.dispatchChangeEvent({ reason: "reset" });

    this.applyFontSize(this.currentState.fontSize);
  }

  buildQuill() {
    // Inserts a '<div class="ql-editor" contenteditable="true">' inside this.element
    // before: div.rendered_text > p(content)
    // after:  div.rendered_text > div.ql-editor(contenteditable) > p(content)
    this.quill = new Quill(this.element, {
      // debug: "info",
      readOnly: this.options.readOnly,
    });

    this.shadowQuillElement = document.createElement("div");
    this.shadowQuill = new Quill(this.shadowQuillElement, {
      readOnly: true,
    });
    this.debug(
      "quill contents:\n",
      JSON.stringify(this.quill.getContents(), null, 2)
    );

    this.shadowQuill.setContents(this.quill.getContents());

    // For debugging:
    // this.element.parentElement.appendChild(this.shadowQuillElement);

    this.dispatchEvent("quill-editor-initialized", {
      quill: this.quill,
      editor: this,
    });

    this.quill.on("selection-change", (range, oldRange, source) => {
      this.debug("selection-change event", range, oldRange, source);
      // Don't use the selection change while making a change, since that would give us outdated values
      if (this.isWithinChange) return;

      if (range) {
        this.debug("lastValidSelection =", JSON.stringify(range));
        this.lastValidSelection = range;
      }

      // When the editor is unfocused, the range is null (calling getFormat would force focus on back on the editor, making it impossible for the user to focus another element),
      // therefore we won't dispatch our format-change event in this case.
      if (range) {
        const format = this.quill.getFormat();
        // this.debug("format", JSON.stringify(format));
        const featureValueDict = parseFontFeaturesCssValue(
          format["font-feature-settings"]
        );

        // Dispatch custom event 'format-change'
        this.dispatchEvent("format-change", {
          features: featureValueDict,
          format,
        });
      }

      if (!oldRange !== !range) {
        this.dispatchEvent("focus-change", {
          hasFocus: (this.hasFocus = !!range),
        });
      }
    });

    this.quill.on("text-change", (delta, oldDelta, source) => {
      this.debug("text-change event", delta, oldDelta, source);
      if (this.shadowQuill) {
        this.shadowQuill.updateContents(delta);
      }
      this.isTextEdited = true;
      this.currentState.contentHtml = this.editorElement.innerHTML;

      this.dispatchChangeEvent({
        reason: "text-change",
        quill: { delta, oldDelta, source },
      });
      this.reapplyLetterSpacing();
    });
  }

  /**
   * @returns {HTMLDivElement}
   */
  get editorElement() {
    return this.element.querySelector(".ql-editor");
  }

  dispatchEvent(name, detail) {
    this.debug("dispatchEvent:", name, detail);
    this.element.dispatchEvent(new CustomEvent(name, { detail }));
  }

  get mainFormat() {
    /** @type import("../types").TypeTesterFormat */
    const r = {};
    for (const key of this.styleKeys) {
      r[key] = this.currentState[key];
    }
    r.fontStyleId = this.currentState.fontStyleId;
    r.fontCssNames = this.getFontCssNames();
    r.letterSpacing = this.currentState.letterSpacing;
    r.columns = this.currentState.columns;
    r.automaticColumnsEnabled = this.currentState.automaticColumnsEnabled;
    r.contentFeatures = this.currentState.contentFeatures;
    const { minLineHeightAscender, minLineHeightDescender } = this.fontStyle;
    r.fontSettings = { minLineHeightAscender, minLineHeightDescender };

    this.debug(`mainFormat ${JSON.stringify(r, null, 2)}`);
    return r;
  }

  get state() {
    return {
      html: this.currentState.contentHtml,
      format: this.mainFormat,
    };
  }

  dispatchChangeEvent(detail = {}) {
    this.dispatchEvent("change", {
      ...this.state,
      ...detail,
    });
  }

  get fontCssName() {
    return removeQuotes(this.element.style["fontFamily"]);
  }

  /**
   * Get the fonts actually used within the text, sorted by prevalence.
   * @returns {string[]} Array of font codes used in the editor, sorted by the number of characters using that font.
   */
  getFontCssNames() {
    const contents = this.quill.getContents();

    // Example contents:
    // {
    //   "ops": [
    //     { "insert": "Baton", "attributes": { "letter-spacing": "-0.12em", "font-family": "f-baton-nouveau-light" } },
    //     { "insert": "\n" },
    //     { "insert": "Nouveau", "attributes": { "letter-spacing": "-0.12em", "font-family": "f-baton-nouveau-light" } },
    //     { "insert": "\n" }
    //   ]
    // }

    const charCountByFontCssName = {};
    charCountByFontCssName[this.fontCssName] = 0; // to count as a default in case there are no non-whitespace characters
    for (const op of contents.ops) {
      const visibleCharCount = op.insert.replaceAll(/\s+/g, "").length;
      if (visibleCharCount === 0) continue;

      const fontCssName =
        (op.attributes && op.attributes["font-family"]) ?? this.fontCssName;
      if (!fontCssName) continue;

      charCountByFontCssName[fontCssName] ??= 0;
      charCountByFontCssName[fontCssName] += visibleCharCount;
    }

    return Object.entries(charCountByFontCssName)
      .sort((a, b) => b[1] - a[1])
      .map(([fontCssName, count]) => fontCssName);
  }

  addEventListener(event, callback) {
    return this.element.addEventListener(event, callback);
  }

  removeEventListener(event, callback) {
    return this.element.removeEventListener(event, callback);
  }

  get currentFormat() {
    const format = this.quill.getFormat();
    return format;
  }

  setFont(fontStyle) {
    const cssName = fontStyle.cssName;

    if (!fontStyle.id) {
      console.error("Font style id is missing while setting the font", {
        cssName,
        fontStyle,
      });
      return;
    }

    if (this.willFormattingApplyToTheWholeText) {
      this.debug(`Setting font for the whole text: ${cssName}`);
      this.setStateProperty("fontFamily", cssName);
      this.setStateProperty("fontStyleId", fontStyle.id);

      this.element.style.fontFamily = cssName;

      this.format("font-family", cssName);
      // this.dispatchChangeEvent is triggered by format()
    } else {
      this.debug(`Setting font on selection: ${cssName}`);
      // Apply font-family to selection
      this.format("font-family", cssName);

      this.determineMainFontStyle();
    }
  }

  get fontStyle() {
    return this.options.fontStyles.find(
      (fs) => fs.id === this.currentState.fontStyleId
    );
  }

  /**
   * Determine the most used font in the text and set it as the main font.
   * Note: Used manually to fix broken records on the samples editing page. (2025-01-13)
   *     TypeTester.all.forEach((tt) => tt.textEditor.determineMainFontStyle())
   */
  determineMainFontStyle() {
    const fontCssNames = this.getFontCssNames();
    this.debug("fontCssNames after setting font on selection", fontCssNames);
    const mainFontCssName = fontCssNames[0];

    // If differente than the current state

    if (mainFontCssName !== this.currentState.fontFamily) {
      this.debug(
        `The most used font is different than the current font, setting it as the main font: ${this.currentState.fontFamily} -> ${mainFontCssName}`
      );
      this.setStateProperty("fontFamily", mainFontCssName);
      this.setStateProperty(
        "fontStyleId",
        this.fontStyleByCssName[mainFontCssName].id
      );
      this.element.style.fontFamily = mainFontCssName;

      this.dispatchChangeEvent({ reason: "font-style-change" });
    }
  }

  /**
   * Apply OpenType features to the selection.
   * Called in FontFeatureSelector when the user toggles a feature.
   *
   * @param {object} featuresDict Example: {"kern":true,"liga":true,"salt":true,"calt":true,"frac":true}
   */
  setFeatures(featuresDict) {
    this.debug("setFeatures", JSON.stringify(featuresDict));
    this.format(
      "font-feature-settings",
      generateFontFeaturesCssValue(featuresDict)
    );
    this.determineOverallFeatures();
  }

  /**
   * Determine the OpenType features used in all of the text and set the them for the sample record,
   * so that the feature can appear ticket even before the editor is focused.
   */
  determineOverallFeatures() {
    const featureCodes = this.getOverallFeatures();
    this.debug("overall featureCodes:", featureCodes);

    const contentFeatures = featureCodes.join(",");
    if (contentFeatures !== this.currentState.contentFeatures) {
      this.setStateProperty("contentFeatures", contentFeatures);
      this.dispatchChangeEvent({ reason: "font-features-change" });
    }
  }

  /**
   * Get the OpenType features used for all of the text.
   */
  getOverallFeatures() {
    const contents = this.quill.getContents();
    // Example contents:
    // {
    //   "ops": [
    //     { "attributes": { "letter-spacing": "0em", "font-feature-settings": "\"kern\", \"liga\", \"salt\", \"calt\", \"frac\"" }, "insert": "1/2" },
    //     { "attributes": { "letter-spacing": "0em", "font-feature-settings": "\"kern\", \"liga\", \"salt\", \"calt\", \"ss04\"" }, "insert": "Kg" },
    //     { "attributes": { "line-height": "1" }, "insert": "\n" }
    //   ]
    // }

    let totalVisibleCharCount = 0;
    const charCountByFeature = {};

    for (const op of contents.ops) {
      const visibleCharCount = op.insert.replaceAll(/\s+/g, "").length;
      if (visibleCharCount === 0) continue;

      totalVisibleCharCount += visibleCharCount;

      const featuresValue =
        op.attributes && op.attributes["font-feature-settings"];
      if (!featuresValue) continue;

      const enabledFeatures = Object.entries(
        parseFontFeaturesCssValue(featuresValue)
      )
        .filter(([key, isEnabled]) => isEnabled)
        .map(([key, isEnabled]) => key);

      for (const featuresKey of withoutDefaultFeatures(enabledFeatures)) {
        charCountByFeature[featuresKey] ??= 0;
        charCountByFeature[featuresKey] += visibleCharCount;
      }
    }

    this.debug("charCountByFeature", charCountByFeature);

    return Object.entries(charCountByFeature)
      .filter(([featureKey, count]) => count >= totalVisibleCharCount)
      .map(([featureKey, count]) => featureKey);
  }

  setStateProperty(key, value) {
    if (properties[key].normalize) {
      value = properties[key].normalize(value);
    }
    this.currentState[key] = value;
    return value;
  }

  async setFontSize(size) {
    size = this.setStateProperty("fontSize", size);

    await this.applyFontSize(size);

    // set data-font-size; this is used when scaling for narrow screens

    this.dispatchChangeEvent({ reason: "font-size-change" });
  }

  async applyFontSize(size) {
    // The editor must not have focus when changing the font size, because that can cause the page to scroll erratically when the number of columns change
    this.quill.blur();

    await nextTick();

    this.element.dataset.unscaledFontSize = size; // for font-scaler, so it can use it for its calculation on resize

    // Displayed font size (with any scaling factor applied, see: font-scaler.js)

    if (this.fontScaler) {
      // Let font-scaler set the display font-size after it receives the `change` event.
      // This is to avoid jitter and bugs when both TypeTester and font-scaler set the font-size,
      // and let font-scaler have more logic (e.g. min/max).
    } else {
      this.element.style.fontSize = `${size}px`;
    }
  }

  /**
   *
   * @param {number} lineHeight integer value between 90 and 200, each unit represents 0.01em
   */
  setLineHeight(lineHeight) {
    this.debug("setLineHeight", lineHeight);
    lineHeight = this.setStateProperty("lineHeight", lineHeight);

    //@ts-ignore
    this.element.style.lineHeight = lineHeight / 100;
    this.format("line-height", lineHeight / 100, { applyToAll: true });

    // this.dispatchChangeEvent is triggered by format()
  }

  /**
   * @param {number} letterSpacing integer value between -12 and 25, each unit represents 0.01em
   */
  setLetterSpacing(letterSpacing) {
    this.debug("setLetterSpacing", letterSpacing);
    letterSpacing = this.setStateProperty("letterSpacing", letterSpacing);

    this.currentState.letterSpacing = letterSpacing;

    this.format("letter-spacing", letterSpacing / 100 + "em", {
      applyToAll: true,
    });
    // this.dispatchChangeEvent is triggered by format()
  }

  get fullRange() {
    return { index: 0, length: this.quill.getLength() };
  }

  format(property, value, { applyToAll = false, skipEvent = false } = {}) {
    this.debug(`format(${property}, ${value}, { applyToAll: ${applyToAll} })`);
    //@ts-ignore
    window.quill = this.quill;
    this.change(() => {
      // Focus so that the text appears selected
      this.quill.focus({ preventScroll: true });

      let rangeToFormat = applyToAll ? this.fullRange : this.lastValidSelection;
      this.debug("rangeToFormat", rangeToFormat, this.quill.getLength());

      const isNone = !rangeToFormat || rangeToFormat.length === 0;
      let isAll =
        applyToAll ||
        (rangeToFormat && rangeToFormat.length === this.quill.getLength() - 1); // -1 because the editor always has at least one newline character, which is not reflected in the selection

      if (isNone) {
        rangeToFormat = this.fullRange;
        isAll = true;
      }

      const { index, length } = rangeToFormat;
      this.debug("formatText", index, length, property, value);

      // https://quilljs.com/docs/api#formattext
      this.quill.formatText(index, length, property, value, "silent");

      if (this.shadowQuill) {
        this.shadowQuill.formatText(
          index,
          isAll ? this.shadowQuill.getLength() : length,
          property,
          value,
          "silent"
        );
      }

      // Fix problem with column gap:
      // The column gap is relative to the current font size and is set on the editor element.
      if (isAll && property === "font-size") {
        this.element.style.fontSize = value;
      }

      // --- This is only relevant if we want to apply font-size to selection
      // Fix problem with line-height:
      // The line-height is applied to paragraphs and font-size is applied to the span within the paragraph,
      // so the font-size is not taken into account when calculating the line-height.
      // Here we set the font-size on the paragraphs that contain a single span.
      // BUG: set font size very high, select text, apply a feature, lower font size => line-height stays very large because a large font-size is applied to part of the text
      // this.element.querySelectorAll(".ql-editor p > span").forEach((span) => {
      //   const p = span.parentElement;
      //   if (p && p.childNodes.length === 1) {
      //     p.style.fontSize = span.style.fontSize;
      //   }
      // });
      // ---

      // #flakycontenthtml - this can make the tests flaky because the order within the styles property is not predictable
      this.currentState.contentHtml = this.editorElement.innerHTML;

      if (!skipEvent) {
        this.dispatchChangeEvent({
          reason: "format-change",
        });
      }
    });
  }

  get isAllSelected() {
    return (
      this.lastValidSelection &&
      this.lastValidSelection.index === 0 &&
      this.lastValidSelection.length === this.quill.getLength() - 1 // -1 because the editor always has at least one newline character, which is not reflected in the selection
    );
  }

  get isNoneSelected() {
    return !this.lastValidSelection || this.lastValidSelection.length === 0;
  }

  get willFormattingApplyToTheWholeText() {
    return this.isAllSelected || this.isNoneSelected;
  }

  change(fn) {
    try {
      this.isWithinChange = true;
      fn();
    } finally {
      this.isWithinChange = false;
    }
  }

  setAlignment(alignment) {
    alignment = this.setStateProperty("textAlign", alignment);

    this.element.style.textAlign = alignment;
    this.dispatchChangeEvent({ reason: "alignment-change" });
  }

  setColumns(columns) {
    // Re-activate automatic column behavior by clicking on the column button
    const reactivateAutoColumns =
      this.currentState.automaticColumnsEnabled === false &&
      columns === this.currentState.columns;

    this.debug("setColumns", columns, reactivateAutoColumns);
    this.applyColumns();
    this.currentState.columns = columns;

    this.currentState.automaticColumnsEnabled = reactivateAutoColumns;

    this.dispatchChangeEvent({ reason: "columns-change" });
  }

  applyColumns(columns) {
    this.debug("applyColumns", columns);
    this.editorElement.style.columnCount = columns;
  }

  reapplyLetterSpacing() {
    // This fixes the following problem:
    // When a paragraph is empty the <p> tag contains a single <br> tag.
    // When you add a letter to the paragraph, it does not wrap it in a <span> tag, which means that the letter-spacing is not applied.
    // Also, the "Fill width" functionality in the sample-editor.js depends on a the <p> tag containing a <span> tag so that it can measure the dimensions of the <span> tag.
    // To fix it, we re-apply the letter-spacing, which will cause the editor to create the necessary <span> tags.
    this.format(
      "letter-spacing",
      this.currentState.letterSpacing / 100 + "em",
      {
        applyToAll: true,
        skipEvent: true, // otherwise an "change" event with reason = "format-change" will be triggered and TextLengthLimiterPlugin will cause the editor to unfocus
      }
    );
  }
}

// { ss01: true, ss02: true } => "ss01" on, "ss02" on
function generateFontFeaturesCssValue(features) {
  const result = [];
  for (const [key, value] of Object.entries(features)) {
    if (value) {
      result.push(`"${key}" ${value ? "on" : "off"}`);
    }
  }
  return result.join(", ");
}

// "ss01" on, "ss02" 1, "ss03" 0 => { ss01: true, ss02: true, ss03: false }
function parseFontFeaturesCssValue(value) {
  if (!value) return {};

  if (Array.isArray(value)) {
    // There are multiple formats used in the selection, return nothing
    return {};
  }

  if (typeof value !== "string") {
    console.warn("Unexpected format for font-feature-settings", value);
    return {};
  }

  const features = value.split(",");
  const result = {};
  for (const feature of features) {
    const [quotedKey, value] = feature.trim().split(" ");
    // const key = quotedKey.slice(1, -1)
    const key = removeQuotes(quotedKey);
    result[key] = !(value === "off" || value === "0");
  }
  return result;
}

function removeQuotes(str) {
  return str.replace(/^["'](.*)["']$/, "$1");
}

function nextTick() {
  return new Promise((resolve) => setTimeout(resolve, 0));
}
