import { isLhUnitSupported, isSafari } from "common/utils/browser-detection";
import { preferences } from "./preferences";

export const useLhUnitsToLimitHeight = () =>
  preferences.useLhUnitsToLimitHeight && isSafari() && isLhUnitSupported();

export const shortenSampleTextToLimitHeight = () =>
  preferences.shortenSampleTextToLimitHeight &&
  isSafari() &&
  !useLhUnitsToLimitHeight();
