import $ from "jquery";

$("[data-toggle-control]").click(function () {
  const $control = $(this);
  const toggleId = $control.attr("data-toggle-control");
  const $target = $(`[data-toggle-target="${toggleId}"]`);

  if ($target.is(":hidden")) {
    $target.slideDown();
    $control.addClass("open");
  } else {
    $control.removeClass("open");
    $target.slideUp();
  }
});
