import { polyfill } from "seamless-scroll-polyfill";
polyfill(); // Safari doesn't support scrollIntoView({ behavior: "smooth" })

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".article__navigation").forEach((nav) => {
    const items = nav.querySelectorAll("li");

    items.forEach((item) => {
      const link = item.querySelector("a");
      const href = link.getAttribute("href");

      const isAnchor = href.startsWith("#");

      if (isAnchor) {
        addSmoothScrollingOnClick(link);
      }
    });
  });
});

function addSmoothScrollingOnClick(link) {
  link.addEventListener("click", (event) => {
    event.preventDefault();

    const target = document.querySelector(link.getAttribute("href"));

    // Get the scroll-padding-top value from the <html>
    const html = document.querySelector("html");
    const scrollPaddingTop = parseInt(getComputedStyle(html).scrollPaddingTop);

    // Get the target element's top position relative to page start
    const targetTop = target.getBoundingClientRect().top + window.scrollY;

    // Note: scrollIntoView doesn't work well in Safari because it does not take into account the scroll-padding-top CSS value
    window.scrollTo({
      top: targetTop - scrollPaddingTop,
      behavior: "smooth",
    });
  });
}
