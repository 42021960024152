import { PopupVisibilityState } from "../ui-utils/PopupVisibilityState";
import { h } from "common/dom-builder";
import { addRemoveClass, onHoverStateChange } from "common/dom-utils";
import { preferences } from "../preferences";

/**
 * A panel that can be opened and closed by clicking on an input group.
 *
 * This class handles generic panel behavior, specific panel-dependent behavior
 * is handled by the panelContents class (FontStyleSelector/etc)
 */
export class Panel {
  containerElement; // .panel-container element
  panelElement; // .panel element
  isVisible = false;

  constructor(
    inputGroup,
    groupName,
    usePushdown,
    typeTester,
    panelContentClass
  ) {
    this.inputGroup = inputGroup;
    this.groupName = groupName;
    this.usePushdown = usePushdown;
    this.parent = this.typeTester = typeTester;
    this.render();

    if (panelContentClass) {
      // new FontFeatureSelector / new FontStyleSelector / new FontSizeSelector / new FontSpacingSelector
      this.panelContents = new panelContentClass({
        closePanel: () => this.hide(),
        font: this.typeTester.props.fontStyle,
        typeTester: this.typeTester,
        panel: this,
      });
    }

    // Needs to be done after panelContents is set
    this.addHoverHandlers();
  }

  render() {
    this.containerElement = h(
      "div",
      {
        class: [
          "panel-container",
          `${this.groupName}-panel-container`,
          this.usePushdown ? "pushdown" : "popup",
        ],
        "data-input-group": this.groupName,
      },
      [
        (this.panelElement = h("div", {
          class: [`tyte-panel`, `${this.groupName}-panel`, "tool-panel"],
        })),
      ]
    );

    if (this.inputGroup) {
      setTimeout(() => {
        // Set in a way that is overridable by CSS classes
        this.containerElement.style.setProperty(
          "--label-offset-left",
          `${this.inputGroup.offsetLeft}px`
        );
      }, 1);
    }

    this.parent.element
      .querySelector(".panels")
      .appendChild(this.containerElement);

    this.initVisibility();

    this.inputGroup?.addEventListener("click", () =>
      this.visibility.toggle(true)
    );
  }

  initVisibility() {
    this.visibility = new PopupVisibilityState(
      this.containerElement,
      this.groupName,
      {
        exclusiveGroup: this.usePushdown
          ? (this.typeTester.settingsVisibilityGroup ??= {})
          : null,
      },
      (isVisible, options) => this.changeVisibility(isVisible, options)
    );
  }

  addHoverHandlers() {
    if (!preferences.showPanelsOnHover) return;

    if (this.panelContents.showPanelWhileSampleActive) return;

    if (this.inputGroup) {
      onHoverStateChange(this.inputGroup, (isHovered) =>
        this.visibility.showHide(isHovered)
      );
    }

    onHoverStateChange(this.panelElement, (isHovered) =>
      this.visibility.showHide(isHovered)
    );
  }

  changeVisibility(isVisible, options) {
    const previousIsVisible = this.isVisible;
    this.isVisible = isVisible;

    if (this.inputGroup) {
      addRemoveClass(this.inputGroup, "open", isVisible);
    }

    if (!options?.hidingBecauseOfOtherPopup && this.usePushdown) {
      this.parent.adjustPushdownHeight(isVisible, this);
    }

    if (this.isVisible !== previousIsVisible) {
      this.containerElement.dispatchEvent(
        new CustomEvent("visibility-change", {
          detail: { isVisible: this.isVisible },
        })
      );
    }
  }

  hide() {
    this.visibility.hide(true);
  }

  get contentHeight() {
    return this.containerElement.offsetHeight;
  }

  addEventListener(event, callback) {
    return this.containerElement.addEventListener(event, callback);
  }

  removeEventListener(event, callback) {
    return this.containerElement.removeEventListener(event, callback);
  }
}
