import { h } from "common/dom-builder";

const columnNumbers = [1, 2, 3];

export class ColumnsSelector {
  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    const element = h(
      "div",
      {
        class: [
          "input-group",
          "columns-input-group",
          "button-group",
          "tool-panel",
        ],
        "data-input-group": "columns",
      },
      columnNumbers.map((columns) =>
        h("div", { class: `icon-button column-${columns}-button` })
      )
    );
    new ColumnsSelector({ element, typeTester });
    return element;
  }

  constructor(props) {
    this.element = props.element;
    this.props = props;
    this.init();
  }

  init() {
    columnNumbers.forEach((columns) => {
      this.element
        .querySelector(`.column-${columns}-button`)
        .addEventListener("click", () => {
          console.log("columns: ", columns);
          this.props.typeTester.textEditor.setColumns(columns);
          this.props.typeTester.element.dispatchEvent(
            new CustomEvent("interaction")
          );
        });
    });
  }
}
