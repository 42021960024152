/**
 * Compute a number that is lowY when x is lowX and highY when x is highX, and linearly
 * interpolate between those values for other x values. The result is constrained to be
 * between min and max if they are provided.
 */
function interpolateValueWithConstraints({
  lowX,
  lowY,
  highX,
  highY,
  min,
  max,
  x,
}) {
  const ratio = (x - lowX) / (highX - lowX);
  let result = lowY + ratio * (highY - lowY);
  if (min !== undefined) {
    result = Math.max(min, result);
  }
  if (max !== undefined) {
    result = Math.min(max, result);
  }
  return result;
}

window.interpolateValueWithConstraints = interpolateValueWithConstraints;
