import { h } from "common/dom-builder";
import { smallIconButton } from "common/icon-buttons";

export class LetterSpacingControl {
  constructor(props) {
    this.props = props;
    this.init();
  }

  init() {
    const { format } = this.props.typeTester.props;
    this.value = format.letterSpacing ?? 0;
    this.render();
    this.updateControlWhenLetterSpacingChanges();
  }

  render() {
    this.element = h(
      "div",
      {
        class: [
          "control",
          "letter-spacing-control",
          "with-icon-in-front",
          "with-value-indicator",
          "tool-panel",
        ],
      },
      [smallIconButton("spacing-horizontal"), this.buildInput()]
    );
    // this.change(this.value); // removed to avoid #flakycontenthtml
    return this.element;
  }

  updateControlWhenLetterSpacingChanges() {
    this.props.typeTester.addEventListener("change", (ev) => {
      if (ev.detail.format.letterSpacing === undefined) return;
      if (ev.detail.format.letterSpacing === this.value) return;
      this.handleChange(ev.detail.format.letterSpacing);
    });
  }

  // Value changed by this control
  change(spacing) {
    this.value = spacing;
    this.props.typeTester.textEditor.setLetterSpacing(spacing);
  }

  // Value changed by another control
  handleChange(spacing) {
    this.value = spacing;
    this.inputElement.value = spacing;
  }

  buildInput() {
    const inputElement = (this.inputElement = h("custom-range-slider", {
      min: -12,
      max: 25,
      step: 1,
      value: this.value,
    }));
    inputElement.addEventListener("input", (ev) => {
      this.change(ev.target.value);
    });
    inputElement.addEventListener("change", (ev) => {
      this.change(ev.target.value);
      this.props.typeTester.element.dispatchEvent(
        new CustomEvent("interaction")
      );
    });
    this.props.typeTester.addEventListener("reset", () => {
      inputElement.value = this.value;
    });
    return inputElement;
  }
}
