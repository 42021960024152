// Which shapes to use from each font file
export const shapeLetters = {
  // store/fonts/ft-menu-1.woff
  1: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
  ],

  // store/fonts/ft-menu-2.woff
  2: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"],

  // store/fonts/ft-menu-3.woff
  3: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ],

  // store/fonts/ft-menu-4.woff
  4: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"],
};
