import debug from "debug";

const log = debug("type-tester:ascender-padding");

export function applyAscendersDescenderCorrection(
  element,
  ascenderCorrection,
  descenderCorrection
) {
  element.style.paddingTop = `${ascenderCorrection}px`;
  element.style.paddingBottom = `${descenderCorrection}px`;

  element.parentElement.style.setProperty(
    "--ascender-padding",
    `${ascenderCorrection}px`
  );
  element.parentElement.style.setProperty(
    "--descender-padding",
    `${descenderCorrection}px`
  );
}

export function correctionPixels(lineHeightNorm, lineHeight, fontSize) {
  const correctionUnscaled = Math.max(0, (lineHeightNorm - lineHeight) / 2);
  const correctionScaled = (correctionUnscaled * fontSize) / 100;
  log(
    `- lineHeightNorm: ${lineHeightNorm}\n` +
      `  lineHeight: ${lineHeight}\n` +
      `  fontSize: ${fontSize}\n` +
      `  correctionUnscaled: ${correctionUnscaled}\n` +
      `  correctionScaled: ${correctionScaled}`
  );
  return correctionScaled;
}
