//@ts-check
import { h } from "common/dom-builder";
import { preferences } from "../preferences";
import "common/components/custom-range-slider";
import { LetterSpacingControl } from "./LetterSpacingControl";
import { LineHeightControl } from "./LineHeightControl";

/**
 * Component containing both the letter-spacing and line-height controls.
 * Forms one unit because the they should be closer together than other controls.
 */
export class FontSpacingSelector {
  parent;
  element;

  constructor(props) {
    this.element = props.element ?? props.panel?.panelElement;
    this.props = props;
    this.letterSpacingControl = new LetterSpacingControl(props);
    this.lineHeightControl = new LineHeightControl(props);
    this.init();
  }

  /**
   *
   * @param {import("../TypeTester").TypeTester} typeTester
   * @returns HTMLDivElement
   */
  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    if (preferences.showSpacingPanelInHeader) {
      const element = h(
        "div",
        {
          class: ["input-group", "spacing-input-group", "in-header"],
          "data-input-group": "spacing",
        },
        []
      );
      new FontSpacingSelector({ typeTester, element });
      return element;
    } else {
      return h(
        "div",
        {
          class: [
            "input-group",
            "spacing-input-group",
            "expandable",
            // "expand-with-pushdown", // feature panel is the only one pushing down
          ],
          "data-input-group": "spacing",
        },
        [
          h("span", { class: "label" }, "spacing"),
          h("span", { class: "icon-button expand-button" }),
        ]
      );
    }
  }

  init() {
    const { format } = this.props.typeTester.props;
    this.currentLineHeight = format.lineHeight ?? 120;

    this.currentLetterSpacing = 0;

    if (preferences.spacingPanelCoversLabel) {
      this.props.panel?.containerElement?.classList?.add("covers-label");
    }
    this.render();
  }

  render() {
    this.element.appendChild(
      h("div", { class: "items" }, [
        this.letterSpacingControl.render(),
        this.lineHeightControl.render(),
      ])
    );
  }
}
