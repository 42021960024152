document.addEventListener("DOMContentLoaded", () => {
  document
    .querySelectorAll(".article--with-expandable-entries")
    .forEach((article) => {
      article.querySelectorAll(".article__entry").forEach((entry) => {
        entry.classList.add("article__entry--expandable");

        const triggers = [
          entry.querySelector(".article__entry-title"),
          entry.querySelector(".article__entry-title a"),
        ];
        const content = entry.querySelector(".article__entry-content");

        const collapsedByDefault =
          article.classList.contains("article--expandable-entries-collapsed") ||
          entry.classList.contains(".article__entry--collapsed");

        let collapsed = collapsedByDefault;

        const collapse = () => {
          collapsed = true;
          entry.classList.add("article__entry--collapsed");
          entry.classList.remove("article__entry--expanded");
          content.style.maxHeight = 0;
        };
        const expand = () => {
          collapsed = false;
          entry.classList.add("article__entry--expanded");
          entry.classList.remove("article__entry--collapsed");
          content.style.maxHeight = content.scrollHeight + "px";
        };

        if (collapsed) {
          collapse();
        } else {
          expand();
        }

        triggers.forEach((trigger) => {
          trigger.style.cursor = "pointer";

          trigger.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (collapsed) {
              expand();
            } else {
              collapse();
            }
          });
        });
      });
    });
});
