function onWindowSize(callback) {
  window.addEventListener("resize", callback);
  document.addEventListener("DOMContentLoaded", callback);
  window.addEventListener("load", callback);
}

onWindowSize(() => {
  document.documentElement.style.setProperty(
    "--scrollbar-width",
    window.innerWidth - document.documentElement.clientWidth + "px"
  );

  // Width without scrollbar
  document.documentElement.style.setProperty(
    "--document-width",
    document.documentElement.clientWidth + "px"
  );
});
