//@ts-check
import { h } from "common/dom-builder";
import { smallIconButton } from "common/icon-buttons";
import { useLhUnitsToLimitHeight } from "../config";

const minLineHeight = 90;
const maxLineHeight = 200;

export class LineHeightControl {
  /**
   * @param {{typeTester: import("../TypeTester").TypeTester, element: HTMLElement}} props
   */
  constructor(props) {
    this.props = props;
    this.init();
  }

  init() {
    const { format } = this.props.typeTester.props;
    this.value =
      format.lineHeight ?? Math.max(120, this.calculatedMinLineHeight);
    this.render();
    this.updateControlWhenLineHeightChanges();

    this.props.typeTester.props.fontStyle.minLineHeightAscender;
  }

  render() {
    this.element = h(
      "div",
      {
        class: [
          "control",
          "line-height-control",
          "with-icon-in-front",
          "tool-panel",
          "with-value-indicator",
        ],
      },
      [smallIconButton("spacing-vertical"), this.buildInput()]
    );
    // this.change(this.value); // removed to avoid #flakycontenthtml
    return this.element;
  }

  get calculatedMinLineHeight() {
    // safarimaxheightissue_option8
    if (useLhUnitsToLimitHeight()) {
      const { format, fontStyle } = this.props.typeTester.props;
      let min = minLineHeight;

      // Use the line-height where the ascender and descender are still visible
      if (fontStyle.minLineHeightAscender) {
        min = Math.max(min, fontStyle.minLineHeightAscender);
      }
      if (fontStyle.minLineHeightDescender) {
        min = Math.max(min, fontStyle.minLineHeightDescender);
      }

      // If the line-height set for the sample is smaller, use that as a minimum
      if (format.lineHeight) {
        min = Math.min(min, this.value);
      }

      return min;
    }

    return minLineHeight;
  }

  updateControlWhenLineHeightChanges() {
    this.props.typeTester.addEventListener("change", (ev) => {
      if (ev.detail.format.lineHeight === undefined) return;
      if (ev.detail.format.lineHeight === this.value) return;
      this.handleChange(ev.detail.format.lineHeight);
    });
  }

  // Value changed by this control
  change(height) {
    this.value = height;
    this.props.typeTester.textEditor.setLineHeight(height);
  }

  // Value changed by another control
  handleChange(height) {
    this.value = height;
    this.inputElement.value = height;
  }

  buildInput() {
    const inputElement = (this.inputElement = h("custom-range-slider", {
      min: this.calculatedMinLineHeight,
      max: maxLineHeight,
      step: 1,
      value: this.value,
    }));
    inputElement.addEventListener("input", (ev) => {
      this.change(ev.target.value);
    });
    inputElement.addEventListener("change", (ev) => {
      this.change(ev.target.value);
      this.props.typeTester.element.dispatchEvent(
        new CustomEvent("interaction")
      );
    });
    this.props.typeTester.addEventListener("reset", () => {
      inputElement.value = this.value;
    });
    return inputElement;
  }
}
