/**
 * A carousel component that shows the current slide in the center and the next/previous slide on each side.
 *
 * Usage example:
 *
 *     const carouselSet = new CarouselSet({
 *       advanceIntervalInSeconds: 5,
 *       transitionDurationInSeconds: 1,
 *       carouselElements: Array.from(document.querySelectorAll(".carousel")),
 *       getFrameElements: (carouselElement) => Array.from(carouselElement.querySelectorAll(".frame")),
 *     });
 *     carouselSet.start();
 *
 * The carousel HTML element must also contain the dimensions, so that the script can calculate the height from the aspect ratio:
 *
 *     <div class="carousel" data-frame-width="16" data-frame-height="9">
 */

// All carousels on the page.
// Ensures that all carousels advance in sync.
class CarouselSet {
  constructor(config) {
    const { carouselElements, getFrameElements } = config;

    this.timing = new CarouselTiming(config);

    this.carousels = carouselElements.map((carouselElement) => {
      const frameElements = getFrameElements(carouselElement);

      const carousel = new Carousel(
        carouselElement,
        frameElements,
        this.timing,
        config
      );

      carousel.init();

      return carousel;
    });
  }

  start() {
    this.timing.start();
  }
}

// ------------------------------------------------------------

// Ensures that all carousels advance in sync
class CarouselTiming {
  constructor(config) {
    this.advanceCallbacks = [];
    this.config = config;
  }

  onAdvance(callback) {
    this.advanceCallbacks.push(callback);
  }
  start() {
    this.restart();
  }

  pause() {
    if (this._interval) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }

  restart() {
    if (this._interval) {
      clearInterval(this._interval);
    }
    this._interval = setInterval(() => {
      this.advanceCallbacks.forEach((callback) => callback());
    }, this.config.advanceIntervalInSeconds * 1000);
  }
}

// ------------------------------------------------------------

class Carousel {
  constructor(element, frameElements, timing, config) {
    this.element = element;
    this.frameElements = frameElements;
    this.timing = timing;
    this.config = config;

    this.currentIndex = 0;

    this.frameOriginalWidth = parseInt(this.element.dataset.frameWidth);
    this.frameOriginalHeight = parseInt(this.element.dataset.frameHeight);
    this.frameAspectRatio = this.frameOriginalWidth / this.frameOriginalHeight;
  }

  init() {
    this.carouselVisibleWidth = this.element.clientWidth;

    this.element.style.overflow = "hidden";

    this.state = new CarouselState(
      this.frameElements.length,
      this.currentIndex
    );
    this.frames = this.frameElements.map(
      (frame, index) =>
        new Frame(frame, index, this.state.positions[index], this)
    );
    this.resize();

    this.addNavigationButtons();

    this.addSwipeHandler();

    window.addEventListener("resize", () => {
      try {
        this.handlingResize = true;
        this.resize();
      } finally {
        this.handlingResize = false;
      }
    });

    this.timing.onAdvance(() => {
      this.gotoNext();
    });
  }

  get currentFrame() {
    return this.frames[this.state.currentIndex];
  }

  addNavigationButtons() {
    this.element.style.position = "relative";

    ["prev", "next"].forEach((direction) => {
      const button = document.createElement("div");
      button.classList.add("action", `action--${direction}`);
      button.innerHTML = "&nbsp;";
      button.addEventListener("click", () => {
        direction === "prev" ? this.gotoPrevious() : this.gotoNext();
      });
      button.style.position = "absolute";
      button.style.top = "0";
      button.style.height = "100%";
      button.style.width = "30%";
      button.style.zIndex = "999";
      button.style.userSelect = "none";
      button.style.webkitUserSelect = "none";
      if (direction === "prev") {
        button.style.left = "0";
        button.style.cursor = "w-resize";
      } else {
        button.style.right = "0";
        button.style.cursor = "e-resize";
      }
      this.element.appendChild(button);
    });
  }

  addSwipeHandler() {
    this.element.addEventListener("touchstart", (touchstartEvent) => {
      // do not prevent default, otherwise the click event will not be triggered

      this.timing.pause();

      const startX = touchstartEvent.touches[0].clientX;
      const startOffset = this.currentFrame.getCurrentLeftOffset();
      let currentX = startX;

      const handleTouchMove = (moveEvent) => {
        moveEvent.preventDefault(); // prevent the browser trying to scroll
        currentX = moveEvent.touches[0].clientX;
        this.updateSwipeDeltaX(currentX - startX - startOffset);
      };
      this.element.addEventListener("touchmove", handleTouchMove);

      const handleTouchEnd = (endEvent) => {
        // do not prevent default, otherwise the click event will not be triggered

        this.clearSwipeDeltaX();
        const endX = endEvent.changedTouches[0].clientX;
        const deltaX = endX - startX;
        if (deltaX > 0) {
          this.gotoPrevious();
        } else if (deltaX < 0) {
          this.gotoNext();
        }

        this.element.removeEventListener("touchmove", handleTouchMove);
        this.element.removeEventListener("touchend", handleTouchEnd);

        this.timing.restart();
      };
      this.element.addEventListener("touchend", handleTouchEnd);
    });
  }

  resize() {
    this.carouselVisibleWidth = this.element.clientWidth;

    const results = calculateDimensions({
      carouselVisibleWidth: this.carouselVisibleWidth,
      frameOriginalWidth: this.frameOriginalWidth,
      frameOriginalHeight: this.frameOriginalHeight,
    });

    this.frameWidth = results.frameWidth;
    this.frameGap = results.frameGap;
    this.height = results.height;

    this.frames.forEach((frame) => frame.updateWidth());

    this.element.style.height = `${this.height}px`;

    this.repositionFrames();

    if (this.config.onSize) {
      this.config.onSize({
        carouselElement: this.element,
        frameWidth: this.frameWidth,
        frameGap: this.frameGap,
        carouselVisibleWidth: this.carouselVisibleWidth,
        centerFrameLeftOffsetPixels: calculateLeftOffset({
          carouselVisibleWidth: this.carouselVisibleWidth,
          frameWidth: this.frameWidth,
          frameGap: this.frameGap,
          relativeFramePos: 0,
        }),
      });
    }
  }

  repositionFrames() {
    this.frames.forEach((frame, index) => {
      frame.updateRelativePosition(
        this.state.positions[index],
        this.swipeDeltaX
      );
    });
  }

  gotoNext() {
    this.state.gotoNext();
    this.repositionFrames();
    this.timing.restart();
  }

  gotoPrevious() {
    this.state.gotoPrevious();
    this.repositionFrames();
    this.timing.restart();
  }

  gotoIndex(index) {
    this.state.gotoIndex(index);
    this.repositionFrames();
    this.timing.restart();
  }

  updateSwipeDeltaX(deltaX) {
    this.swipeDeltaX = deltaX;
    this.repositionFrames();
  }

  clearSwipeDeltaX() {
    this.swipeDeltaX = null;
  }
}

// ------------------------------------------------------------

class Frame {
  constructor(element, index, relativePosition, carousel) {
    this.element = element;
    this.index = index;
    this.carousel = carousel;
    this.relativePosition = relativePosition;
    this.swipeDeltaX = null;
    this.isTransitioning = false;
    this.updateRelativePosition();

    this.element.addEventListener("click", () => {
      carousel.gotoIndex(this.index);
    });
    this.init();
  }

  init() {
    this.element.style.position = "absolute";
  }

  updateWidth() {
    this.element.style.width = `${this.carousel.frameWidth}px`;
  }

  updateRelativePosition(newRelativePosition, swipeDeltaX = null) {
    const oldRelativePosition = this.relativePosition;

    this.relativePosition = newRelativePosition;

    const { transitionDurationInSeconds } = this.carousel.config;

    this.recalculateLeftOffset(swipeDeltaX);

    const isMovingToOtherSide =
      typeof oldRelativePosition === "number" &&
      (newRelativePosition === 0 ||
        oldRelativePosition === 0 ||
        Math.sign(newRelativePosition) === Math.sign(oldRelativePosition));

    if (
      isMovingToOtherSide &&
      !this.carousel.handlingResize &&
      swipeDeltaX === null
    ) {
      // Move smoothly
      this.element.style.transition = `left ${transitionDurationInSeconds}s ease-out`;
      this.isTransitioning = true;
    } else {
      // Move instantly from one end to the other
      this.element.style.transition = "none";
    }
    this.element.style.left = `${this.leftOffsetPixels}px`;

    if (this.index === 0) {
      setTimeout(() => {
        this.isTransitioning = false;
      }, transitionDurationInSeconds * 1000);
    }
  }

  recalculateLeftOffset(swipeDeltaX) {
    const { carouselVisibleWidth, frameWidth, frameGap } = this.carousel;
    const relativeFramePos = this.relativePosition;

    const leftOffsetPixels =
      calculateLeftOffset({
        carouselVisibleWidth,
        frameWidth,
        frameGap,
        relativeFramePos,
      }) + (swipeDeltaX ?? 0);

    this.leftOffsetPixels = leftOffsetPixels;
  }

  // How far is the frame from where it should be? This can be queried mid-transition. It can be used so that when the user starts swiping we can move the frame from where it actually is, instead of its css left value (which is the end of the transition)
  getCurrentLeftOffset() {
    const rect = this.element.getBoundingClientRect();
    return this.leftOffsetPixels - rect.left;
  }
}

// ------------------------------------------------------------

// Where is each slide relative to the current slide?
class CarouselState {
  constructor(count, currentIndex = 0) {
    this.count = count;
    this.currentIndex = currentIndex;
    this.update();
  }

  gotoNext() {
    this.gotoIndex((this.currentIndex + 1) % this.count);
  }

  gotoPrevious() {
    this.gotoIndex((this.currentIndex - 1 + this.count) % this.count);
  }

  gotoIndex(index) {
    this.currentIndex = index;
    this.update();
  }

  update() {
    // this.positions = [0, 1, 2, 3, 4, 5, 6, -5, -4, -3, -2, -1] -- where each slide is relative to the current slide (the first slide is the current one)
    //                  [-1, 0, 1, 2, 3, 4, 5, 6, -5, -4, -3, -2] -- after moving to the next slide
    this.positions = Array.from({ length: this.count }, (_, index) => {
      const leftPosition =
        (this.count + this.currentIndex - index) % this.count;
      const rightPosition =
        (this.count + index - this.currentIndex) % this.count;
      const relativePos =
        leftPosition < rightPosition ? -leftPosition : rightPosition;
      return relativePos;
    });
  }
}

// ------------------------------------------------------------

function proportiallyBetween({
  min,
  max,
  minContainer,
  maxContainer,
  containerSize,
}) {
  if (containerSize <= minContainer) {
    return min;
  }
  if (containerSize >= maxContainer) {
    return max;
  }
  const ratio = (containerSize - minContainer) / (maxContainer - minContainer);
  return min + ratio * (max - min);
}

function calculateDimensions({
  carouselVisibleWidth,
  frameOriginalWidth,
  frameOriginalHeight,
}) {
  const frameGap = interpolateValueWithConstraints({
    lowX: 1000,
    lowY: 15,
    highX: 1736,
    highY: 200,
    min: 15,
    max: 200,
    x: carouselVisibleWidth,
  });

  const nextFrameVisibleWidth = interpolateValueWithConstraints({
    lowX: 600,
    lowY: 0,
    highX: 1736,
    highY: 200,
    min: 0,
    x: carouselVisibleWidth,
  });

  const frameWidth =
    carouselVisibleWidth - frameGap * 2 - nextFrameVisibleWidth * 2;

  const height = frameWidth / (frameOriginalWidth / frameOriginalHeight);

  return { frameWidth, frameGap, height };
}

function calculateLeftOffset({
  carouselVisibleWidth,
  frameWidth,
  frameGap,
  relativeFramePos,
}) {
  const leftOffset =
    carouselVisibleWidth / 2 -
    frameWidth / 2 +
    relativeFramePos * (frameWidth + frameGap);

  return leftOffset;
}

// ------------------------------------------------------------

function callIfFunction(value, options) {
  return typeof value === "function" ? value(options) : value;
}

// ------------------------------------------------------------

// Make it available for any scripts in static pages
window.carousels = {
  CarouselSet,
  Carousel,
};
