// Make the sidebar menu sticky when scrolling (on desktop layout)

const pageHorizontalMargin = parseInt(
  getComputedStyle(document.documentElement)
    .getPropertyValue("--page-horizontal-margin")
    .replace("px", "")
);

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".article__navigation").forEach((nav) => {
    // Remember the initial position of the nav, when it's not fixed yet
    const offsetTop = nav.offsetTop;

    // State
    let isFixed = false;

    // Create a fake navigation element that stays in the grid, so that we can use its width to set the width of the fixed nav
    const placeholder = document.createElement("div");
    placeholder.style.visibility = "hidden";
    placeholder.style.height = 0;
    placeholder.classList.add("article__navigation");
    placeholder.style.gridArea = "left"; // place in the same grid area as the nav, so that it will get its width based on css
    placeholder.style.maxWidth = nav.style.maxWidth;
    nav.parentNode.insertBefore(placeholder, nav.nextSibling);

    const updateWhetherFixed = () => {
      if (window.scrollY >= offsetTop) {
        if (!isFixed) {
          isFixed = true;
          updatePositionAndWidth();
          nav.classList.add("article__navigation--fixed");
        }
      } else {
        if (isFixed) {
          isFixed = false;
          updatePositionAndWidth();
          nav.classList.remove("article__navigation--fixed");
        }
      }
    };

    const updatePositionAndWidth = () => {
      nav.style.width = isFixed ? `${placeholder.offsetWidth}px` : "";
      nav.style.left = isFixed
        ? `${placeholder.offsetLeft + pageHorizontalMargin}px`
        : "";
    };

    window.addEventListener("scroll", updateWhetherFixed);
    window.addEventListener("resize", updatePositionAndWidth);

    updateWhetherFixed();
  });
});
