// @ts-check
/**
 * @typedef {import('../TypeTester.js').TypeTester} TypeTester
 * @typedef {import('../text-editor/TextEditor.js').TextEditor} TextEditor
 */

import { isBlank } from "common/utils/blank.js";
import { isSafari } from "common/utils/browser-detection.js";
import debug from "debug";
import { shortenSampleTextToLimitHeight } from "../config.js";
import { sleep } from "common/utils/sleep.js";

/**
 * Cuts the text length so that it does not get unreasonably long.
 *
 * Some samples were designed with the max-height in mind, so they are very long, but max-height does not work properly in Safari.
 * Instead of shortening the samples manually, we can use this plugin to do it automatically.
 *
 * #safarimaxheightissue_option6
 */
export class TextLengthLimiterPlugin {
  /**
   * @param {TypeTester} typeTester
   */
  constructor(typeTester) {
    this.typeTester = typeTester;

    this.log = debug("type-tester:text-length-limiter-plugin");

    if (!shortenSampleTextToLimitHeight()) {
      this.log(`inactive (shortenSampleTextToLimitHeight)`);
      return;
    }

    typeTester.addEventListener(
      "text-editor-instantiated",
      ({ detail: { textEditor } }) => this.onEditorInstantiated(textEditor)
    );
  }

  /**
   * @param {TextEditor} textEditor
   */
  onEditorInstantiated(textEditor) {
    textEditor.addEventListener(
      "quill-editor-initialized",
      ({ detail: { quill } }) => {
        this.limitTextLength(quill);

        textEditor.addEventListener("change", (event) => {
          // We should do any shortening while the user is typing, it will cause the editor to unfocus.
          if (event.detail.reason !== "text-change") {
            // this.log(`>>> change`, event.detail.reason);
            this.limitTextLength(quill);
          }
        });
      }
    );
  }

  /**
   * @param {*} quill
   */
  async limitTextLength(quill) {
    const { textEditor } = this.typeTester;
    const { fontSize, lineHeight, letterSpacing } = textEditor.mainFormat;

    /** @type number | undefined | null */
    const maxHeight = this.typeTester.props.options.maxHeight;

    if (isBlank(maxHeight)) {
      this.log(`maxHeight is blank, skipping`);
      return;
    }

    const characterHeight = (fontSize * lineHeight) / 100;

    const magicNumber = 1.2;

    const characterWidthCoefficient = textEditor.fontStyle.charWidth / 100;
    const letterSpacingPxPerEm = letterSpacing / 100; // Letter-spacing is defined as 0.01em, ie. 1/100th of the font size
    const characterWidth =
      (fontSize * characterWidthCoefficient + fontSize * letterSpacingPxPerEm) *
      magicNumber;

    if (characterWidth < 1) {
      console.warn(
        `text-length-limiter: characterWidth is too small, skipping`
      );
      return;
    }

    const characterArea = characterHeight * characterWidth;

    const containerWidth = 1000; // font size is set for 1000px, the actual font size is scaled according to the screen width; therefor for this calculation the width is irrelevant

    const containerArea = containerWidth * maxHeight;

    let characterLimit = Math.ceil(containerArea / characterArea);

    this.log(
      `characterLimit: ${characterLimit}\n` +
        `  characterHeight: ${characterHeight}\n` +
        `  characterWidth: ${characterWidth} (letterSpacing: ${letterSpacing})\n` +
        `  characterArea: ${characterArea}\n` +
        `  containerWidth: ${containerWidth}\n` +
        `  containerArea: ${containerArea}\n` +
        ``
    );

    const shadowQuill = textEditor.shadowQuill;
    if (!shadowQuill) {
      this.log(`shadowQuill not found, skipping`);
      return;
    }

    // First, reapply all the full content to the editor so that we can get the text
    quill.setContents(
      JSON.parse(JSON.stringify(shadowQuill.getContents())),
      "silent"
    );

    const text = shadowQuill.getText();

    // Find the first space after the character limit
    const spaceIndex = indexOfRegex(text, /[ ,;\n]/, characterLimit);
    if (spaceIndex !== -1 && spaceIndex < characterLimit + 20) {
      characterLimit = spaceIndex;
    }

    // Find the first period or sentence-end punctuation after the character limit with regex
    // const punctuationIndex = text.substring(characterLimit).search(/[.!?]/);
    // if (punctuationIndex !== -1 && punctuationIndex < characterLimit + 40) {
    //   characterLimit += punctuationIndex + 1;
    // }

    if (characterLimit < 0) {
      console.warn(`text-length-limiter: characterLimit is negative, skipping`);
      return;
    }

    // Note: The second argument is the length of the text to delete, but there is a bug that results in the whole text being deleted, which does not seem to occur when using a larger number.
    quill.deleteText(characterLimit, quill.getLength(), "silent");
  }
}

const indexOfRegex = (text, regex, fromIndex = 0) => {
  const match = text.substring(fromIndex).match(regex);
  if (!match) return -1;
  return match.index + fromIndex;
};
