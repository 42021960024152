import { h } from "common/dom-builder";

export class ResetSelector {
  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    this.element = h(
      "div",
      {
        class: [
          "input-group",
          "reset-input-group",
          "button-group",
          "tool-panel",
        ],
        "data-input-group": "reset",
      },
      [
        h("div", {
          class: "icon-button refresh-button",
          "data-id": "reset-button",
        }),
      ]
    );

    new ResetSelector({ element: this.element, typeTester });
    return this.element;
  }

  constructor(props) {
    this.element = props.element;
    this.props = props;
    this.init();
  }

  init() {
    this.element
      .querySelector(".refresh-button")
      .addEventListener("click", () => {
        this.props.typeTester.reset();
        this.props.typeTester.element.dispatchEvent(
          new CustomEvent("interaction")
        );
      });
  }
}
