export function deepCamelizeKeys(obj) {
  if (obj == null || typeof obj === "undefined") return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCamelizeKeys(item));
  } else if (typeof obj === "object") {
    const newObj = {};
    for (const key in obj) {
      newObj[camelize(key)] = deepCamelizeKeys(obj[key]);
    }
    return newObj;
  } else {
    return obj;
  }
}

export function camelize(str) {
  return str.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
}
