import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "button"];

  copy() {
    const textToCopy = this.textTarget.value;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        this.buttonTarget.textContent = "Copied!";
        setTimeout(() => {
          this.buttonTarget.textContent = "Copy";
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
  }
}
