// data-match-height-of

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.querySelectorAll("[data-match-height-of]");

  if (elements.length > 0) {
    onWindowSize(() => {
      elements.forEach((element) => {
        const referencedId = element.dataset.matchHeightOf;
        const referencedElement = document.getElementById(referencedId);
        if (!referencedElement) {
          console.warn("Referenced element not found by ID:", referencedId);
          return;
        }
        const height = referencedElement.clientHeight;
        element.style.height = `${height}px`;
      });
    });
  }
});

function onWindowSize(callback) {
  callback();
  window.addEventListener("resize", callback);
}
